import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 410px;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;

  img {
    width: 210px;
  }

  h1 {
    margin-top: 24px;
  }

  p {
    margin-top: 10px;
    text-align: center;

    span {
      margin-top: 20px;
    }
  }
`;

export const PaymentLink = styled.a`
  width: 140px;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #4ceb5b;
  font-weight: bold;
  color: #fff;
  margin-top: 32px;
  margin-bottom: 32px;
  cursor: pointer;
  border-radius: 7px;
  text-decoration: none;
  img {
    width: 90px;
  }
`;

export const Button = styled.button`
  width: 100%;
  max-width: 414px;
  height: 48px;
  position: fixed;
  bottom: 0px;
  background: linear-gradient(
    144deg,
    rgba(251, 74, 32, 1) 28%,
    rgba(250, 5, 97, 1) 100%
  );
  color: #fff;
  border-radius: 7px 7px 0 0;
  border: none;
`;
