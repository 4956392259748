import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  background: #f8f8f8;
  min-height: 100vh;
  width: 100%;
  max-width: 410px;
`;

export const Content = styled.div`
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Message = styled.div`
  display: flex;
  padding: 15px;
  justify-content: space-between;
  align-items: center;
  color: #333;

  img {
    width: 120px;
  }

  h1 {
    margin-top: 8px;
    margin-left: 8px;
    font-size: 14px;
    text-align: center;
  }
  p {
    margin-top: 16px;
    text-align: center;
    font-size: 14px;
    span {
      margin-top: 20px;
    }
  }
`;

export const Restaurants = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 410px;
  align-items: center;
  justify-content: center;
`;

export const Restaurant = styled.div`
  position: relative;
  width: 90%;
  height: 115px;
  background: #fff;
  box-shadow: 0 0 15px #145e7e20;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  padding: 0 8px;
  & + div {
    margin-top: 10px;
  }
`;
export const ButtonsAction = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 414px;
  height: 48px;
  background: #0aa5ff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: none;
  border-radius: 7px 7px 0px 0px;
  svg {
    margin-right: 4px;
  }
`;

export const RestaurantsAvatar = styled.div`
  margin-top: 12px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  max-width: 410px;
  width: 90%;
  padding-bottom: 80px;
`;

export const Avatar = styled.div`
  position: relative;

  border-radius: 7px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 0px 0px 31px -20px rgba(120, 120, 120, 1);
  -moz-box-shadow: 0px 0px 31px -20px rgba(120, 120, 120, 1);
  box-shadow: 0px 0px 31px -20px rgba(120, 120, 120, 1);
  img {
    width: 80%;

    ${props =>
      props.opened.toString() === 'false' &&
      css`
        opacity: 40%;
      `}
  }
`;

export const PromotionFood = styled.div`
  width: 140px;
  height: 120px;
  display: flex;
  justify-content: flex-end;

  ${props =>
    props.opened.toString() === 'false' &&
    css`
      opacity: 40%;
    `}
`;

export const ProfileFood = styled.div`
  width: 170px;
  height: 110px;

  ${props =>
    props.opened.toString() === 'false' &&
    css`
      opacity: 80%;
    `}
`;

export const ProfileAvatar = styled.div`
  width: 80px;
  height: 70px;
  background-color: #fff;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 70px;
  }
`;

export const InfoFood = styled.div`
  width: 130px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AttributesFood = styled.div`
  height: 75px;
  display: flex;
  align-items: center;
`;

export const CoupomBadge = styled.div`
  position: absolute;
  bottom: 14px;
  left: 50px;
  border-radius: 7px;
  font-size: 10px;
  width: 40px;
  height: 16px;
  background-color: orange;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LocalFood = styled.div`
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;

  > p {
    font-size: 10px;
    font-weight: normal;
    text-align: center;

    svg {
      margin-right: 4px;
    }
  }
`;

export const ProfileContent = styled.div`
  width: 110px;
  height: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > p {
    font-weight: bold;
    font-size: 12px;
    margin-top: 4px;
    text-align: center;
  }

  ${props =>
    props.opened.toString() === 'false' &&
    css`
      color: #33333380;
    `}
`;

export const PromotionBody = styled.div`
  width: 140px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
  p {
    font-size: 10px;
  }
  img {
    width: 90px;
    height: 85px;
    border-radius: 50%;
    position: absolute;
    top: 4px;

    border: 3px solid #a47a1e;
  }
`;

export const PromotionTitle = styled.div`
  width: 130px;
  min-height: 35px;

  position: absolute;
  border-radius: 5px;
  top: 73px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  background: linear-gradient(
    144deg,
    #956d13 15%,
    #ffda7d 30%,
    #e6be69 45%,
    #ffec94 60%,
    #d3a84c 75%,
    #a47a1e 100%
  );
  p {
    font-size: 8px;
    text-transform: uppercase;
    text-align: center;
    color: #000;
  }

  div {
    display: flex;
    justify-content: center;

    p {
      font-size: 10px;
    }
  }
`;

export const ModalContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  strong {
    text-align: center;
    font-weight: bold;
  }
  p {
    text-align: center;
  }
`;

export const ModalBody = styled.div`
  margin-top: 16px;
`;

export const StarRatings = styled.div`
  color: #ffa500;
`;

export const ModalFooter = styled.div`
  margin-top: 16px;

  button {
    border: none;
    border-radius: 7px;
    padding: 10px 15px;
    background: #0aa5ff;
    color: #fff;
  }
`;

export const ShoppingLoading = styled.div`
  width: 100%;
  max-width: 410px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #0aa5ff;

  h1 {
    margin-top: 16px;
    font-size: 18px;
    color: #fff;
  }

  img {
    width: 100px;
    height: 150px;
  }
`;

export const DevelopedBy = styled.div`
  display: flex;
  padding-bottom: 80px;

  p {
    font-size: 14px;
  }

  a {
    text-decoration: none;
    color: #ec0048;
    margin-left: 4px;
  }
`;

export const RestaurantClosed = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  background: #ff0000;
  color: #fff;
  width: 40%;
  height: 25px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 7px 0 0 0;

  p {
    text-align: center;
    font-size: 12px;

    ${props =>
      props.priority === false &&
      css`
        font-size: 8px;
      `}
  }

  ${props =>
    props.priority === false &&
    css`
      width: 100%;
      height: 15px;
      border-radius: 7px 7px 0 0;
    `}
`;

export const ButtonCoupons = styled.button`
  display: flex;
  align-items: center;
  color: #fff;
  border: 0;
  background: none;
  position: relative;
`;

export const ButtonOrders = styled.button`
  display: flex;
  align-items: center;
  color: #fff;
  border: 0;
  background: none;
`;
