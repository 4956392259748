import styled, { css } from 'styled-components';
import CurrencyInput from 'react-currency-masked-input';

import Select from 'react-select';

export const Container = styled.div`
  margin: 0 auto;
  background: #fff;
  width: 100%;
  max-width: 410px;

  /* display: flex;
  justify-content: center;
  align-items: center; */
`;

export const Content = styled.div`
  color: #333;

  min-height: 100vh;

  display: flex;
  flex-direction: column;

  align-items: center;
`;

export const Header = styled.header`
  padding-right: 10px;
  padding-left: 10px;
  width: 100%;
  height: 60px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;

  -webkit-box-shadow: 0px 1px 31px -20px rgba(120, 120, 120, 1);
  -moz-box-shadow: 0px 1px 31px -20px rgba(120, 120, 120, 1);
  box-shadow: 0px 1px 31px -20px rgba(120, 120, 120, 1);

  h1 {
    font-size: 18px;
    font-weight: bold;
  }
`;

export const Title = styled.div`
  padding: 10px;

  p {
    font-size: 14px;
    text-align: center;
  }
`;

export const SelectField = styled.div`
  width: 90%;
  max-width: 410px;
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  p {
    font-size: 16px;
    text-align: left;
    margin-bottom: 4px;
    font-weight: bold;
  }

  & + div {
    margin-top: 24px;
  }
`;

export const SelectStyled = styled(Select)`
  width: 100%;
  margin-top: 16px;

  & + & {
    margin-top: 16px;
  }
`;

export const PaymentsMethods = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;

  padding-bottom: 60px;
`;

export const PaymentsMethodTitle = styled.p`
  width: 90%;
  font-size: 16px;
  margin-bottom: 8px;
  text-align: left;
  font-weight: bold;
`;

export const PaymentsMethodHeader = styled.div`
  width: 90%;

  p {
    text-align: left;
    margin-top: 16px;
  }
`;

export const PaymentCheck = styled.input`
  width: 90%;
  height: 80px;
  box-shadow: 0 0 15px #33333320;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;

  & + div {
    margin-top: 12px;
  }

  div {
    display: flex;
    justify-content: center;
    width: 30%;

    img {
      width: 50px;
      height: 50px;
      margin-left: 16px;
      border-radius: 50%;
    }
  }

  p {
    width: 70%;
    text-align: center;
    font-weight: bold;
  }
`;

export const Payment = styled.div`
  width: 90%;
  height: 70px;
  box-shadow: 0 0 15px #33333320;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;

  /* &:active{
    box-shadow: 0 0 15px #ff0000;
  } */

  ${props =>
    props.focused &&
    css`
      box-shadow: 0 0 15px #fb4a20;
    `}

  & + div {
    margin-top: 12px;
  }

  div {
    display: flex;
    justify-content: center;
    width: 30%;

    img {
      width: 50px;
      height: 50px;
      margin-left: 16px;
    }
  }

  p {
    width: 70%;
    text-align: center;
    font-weight: bold;
  }
`;

export const OrderValue = styled.div`
  width: 100%;
  max-width: 390px;
  margin-top: 16px;
  display: flex;
  padding-bottom: 16px;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  p {
    font-weight: bold;
    color: #333;

    & + p {
      margin-top: 8px;
    }
  }
`;

export const ButtonOrders = styled.button`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 414px;
  height: 48px;
  background: linear-gradient(
    144deg,
    rgba(251, 74, 32, 1) 28%,
    rgba(250, 5, 97, 1) 100%
  );
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: 7px 7px 0px 0px;

  svg {
    margin-right: 4px;
  }
`;

export const ModalTitle = styled.div`
  p {
    text-align: left;
    font-weight: bold;
  }
`;

export const ModalBody = styled.div`
  width: 100%;
  max-width: 410px;
  margin-top: 8px;

  input {
    margin-top: 18px;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid #33333310;
  display: flex;
  justify-content: space-around;
`;

export const UserChangeInput = styled(CurrencyInput)`
  margin-top: 16px;
  margin-bottom: 16px;
  border-radius: 5px;
  padding: 5px 10px;
  border: 1px solid #33333330;
`;

export const ButtonConfirmOrder = styled.button`
  display: flex;
  align-items: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #77dd77;
  border: none;
  border-radius: 7px;
`;

export const ButtonCancelOrder = styled.button`
  display: flex;
  align-items: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #ff1814;
  border: none;
  border-radius: 7px;
  margin-left: 4px;
`;

export const OrderItem = styled.div``;

export const MenuItemBody = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 20px;

  & + div {
    margin-top: 20px;
  }
`;

export const MenuItemImage = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 52px;
    height: 52px;
    border-radius: 7px;
  }
`;

export const MenuItemDescription = styled.div`
  margin-left: 8px;
  div {
    display: flex;
    align-items: center;
    margin-top: 5px;

    span {
      font-size: 12px;
    }
  }

  p {
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
  }

  strong {
    font-size: 14px;
  }
`;

export const MenuItemComplements = styled.div`
  display: flex;
  flex-wrap: wrap;

  p {
    color: #fb4a20;
    font-size: 10px;
    padding: 4px;
    border: 1px solid #fb4a20;
    border-radius: 14px;
    margin-right: 4px;

    & + p {
      margin-top: 4px;
    }
  }
`;

export const PicPayInvite = styled.div`
  width: 90%;
  max-width: 410px;
  height: 220px;
  background: #22c465;
  border-radius: 7px;
  color: #fff;
  padding: 12px;

  h1 {
  }

  p {
    margin-top: 12px;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    color: #22c465;
    background: #fff;
    border: none;
    border-radius: 7px;
    text-decoration: none;
    margin-top: 12px;

    svg {
      margin-right: 8px;
    }
  }
`;

export const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #4ceb5b;
  border: none;
  border-radius: 7px;
  margin-right: 8px;
`;

export const CancelButton = styled.button`
  display: flex;
  align-items: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #ff1814;
  border: none;
  border-radius: 7px;
  margin-left: 4px;
`;

export const DeliveryTax = styled.p`
  width: 90%;
  font-size: 12px;
  background: #4ceb5b;
  color: #fff;
  border-radius: 7px;
  padding: 5px 10px;
  text-align: center;
`;
