import React, { useRef } from 'react';

import {
  Container,
  Discount,
  CouponDetails,
  Title,
  Subtitle,
  CouponCode,
  Code,
  CodeDetails,
  ButtonCopyCode,
  RestaurantName,
  DiscountName,
} from './styles';

const CouponModel = ({
  name,
  code,
  title,
  description,
  avatar,
  restaurantName,
}) => {
  const codeRef = useRef(null);

  function copyCode() {
    const inputCode = codeRef.current;

    inputCode.select();
    inputCode.setSelectionRange(0, 99999);

    document.execCommand('copy');
  }

  return (
    <Container>
      <Discount>
        <img src={avatar} alt="Logotipo do restaurante" />
        <RestaurantName>{restaurantName}</RestaurantName>
        <DiscountName>{name}</DiscountName>
      </Discount>
      <CouponDetails>
        <Title>{title}</Title>
        <Subtitle>{description}</Subtitle>
        <CouponCode>
          <CodeDetails>
            <p>Código:</p>
            <Code type="text" value={code} ref={codeRef} readOnly />
          </CodeDetails>

          <ButtonCopyCode onClick={copyCode}>Copiar código</ButtonCopyCode>
        </CouponCode>
      </CouponDetails>
    </Container>
  );
};

export default CouponModel;
