import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';

import api from '../../services/api';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [userToken, setUserToken] = useState(() => {
    const tokenStorage = localStorage.getItem('@qrexpress:userToken');

    if (tokenStorage) {
      api.defaults.headers.Authorization = `Bearer ${tokenStorage}`;
      return tokenStorage;
    }

    return '';
  });

  const [orderPicPayId, setOrderPicPayId] = useState(() => {
    const orderPicPayIdStorage = localStorage.getItem(
      '@qrexpress:orderPicPayId',
    );

    if (orderPicPayIdStorage) {
      return orderPicPayIdStorage;
    }

    return '';
  });

  const [picpayUrlPayment, setPicpayUrlPayment] = useState(() => {
    const picpayUrlPaymentStorage = localStorage.getItem(
      '@qrexpress:picpayUrlPayment',
    );

    if (picpayUrlPaymentStorage) {
      return picpayUrlPaymentStorage;
    }

    return '';
  });

  const [picpayReferenceId, setPicpayReferenceId] = useState(() => {
    const picpayReferenceIdStorage = localStorage.getItem(
      '@qrexpress:picpayReferenceId',
    );

    if (picpayReferenceIdStorage) {
      return picpayReferenceIdStorage;
    }

    return '';
  });

  const [userPhone, setUserphone] = useState(() => {
    const authStorage = localStorage.getItem('@qrexpress:userPhone');

    if (authStorage) {
      return authStorage;
    }

    return '';
  });

  const [userFirstName, setUserFirstName] = useState(() => {
    const userFirstNameStorage = localStorage.getItem(
      '@qrexpress:userFirstName',
    );

    if (userFirstNameStorage) {
      return userFirstNameStorage;
    }

    return '';
  });

  const [userLastName, setUserLastName] = useState(() => {
    const userLastNameStorage = localStorage.getItem('@qrexpress:userLastName');

    if (userLastNameStorage) {
      return userLastNameStorage;
    }

    return '';
  });

  const [userEmail, setUserEmail] = useState(() => {
    const userEmailStorage = localStorage.getItem('@qrexpress:userEmail');

    if (userEmailStorage) {
      return userEmailStorage;
    }

    return '';
  });

  const [userChosePaySms, setUserChosePaySms] = useState(() => {
    const userChosePaySmsStorage = localStorage.getItem(
      '@qrexpress:userChosePaySms',
    );

    if (userChosePaySmsStorage) {
      return userChosePaySmsStorage;
    }

    return '';
  });

  const [clientPaySms, setClientPaySms] = useState(() => {
    const clientPaySmsStorage = localStorage.getItem('@qrexpress:clientPaySms');

    if (clientPaySmsStorage) {
      return clientPaySmsStorage;
    }

    return '';
  });

  const [isSmsServiceOptional, setIsSmsServiceOptional] = useState(() => {
    const isSmsServiceOptionalStorage = localStorage.getItem(
      '@qrexpress:isSmsServiceOptional',
    );

    if (isSmsServiceOptionalStorage) {
      return isSmsServiceOptionalStorage;
    }

    return '';
  });

  const [restaurantHasSmsService, setRestaurantHasSmsService] = useState(() => {
    const restaurantHasSmsServiceStorage = localStorage.getItem(
      '@qrexpress:restaurantHasSmsService',
    );

    if (restaurantHasSmsServiceStorage) {
      return restaurantHasSmsServiceStorage;
    }

    return '';
  });

  const [userPaymentMethod, setUserPaymentMethod] = useState(() => {
    const userPaymentMethodStorage = localStorage.getItem(
      '@qrexpress:userPaymentMethod',
    );

    if (userPaymentMethodStorage === 'picpay') {
      return '';
    }

    if (userPaymentMethodStorage === 'withdrawal') {
      return '';
    }

    if (userPaymentMethodStorage === 'credito') {
      return '';
    }

    if (userPaymentMethodStorage) {
      return JSON.parse(userPaymentMethodStorage);
    }

    return '';
  });

  const [restaurantId, setRestaurantId] = useState(() => {
    const restaurantIdStorage = localStorage.getItem('@qrexpress:restaurantId');

    if (restaurantIdStorage) {
      return restaurantIdStorage;
    }

    return '';
  });

  const [restaurantUsername, setRestaurantUsername] = useState(() => {
    const restaurantUsernameStorage = localStorage.getItem(
      '@qrexpress:restaurantUsername',
    );

    if (restaurantUsernameStorage) {
      return restaurantUsernameStorage;
    }

    return '';
  });

  const [shoppingName, setShoppingName] = useState(() => {
    const shoppingNameStorage = localStorage.getItem('@qrexpress:shoppingName');

    if (shoppingNameStorage) {
      return shoppingNameStorage;
    }

    return '';
  });

  const [shoppingId, setShoppingId] = useState(() => {
    const shoppingIdStorage = localStorage.getItem('@qrexpress:shoppingId');

    if (shoppingIdStorage) {
      return shoppingIdStorage;
    }

    return '';
  });

  const [restaurantFantasyName, setRestaurantFantasyName] = useState(() => {
    const restaurantFantasyNameStorage = localStorage.getItem(
      '@qrexpress:restaurantFantasyName',
    );

    if (restaurantFantasyNameStorage) {
      return restaurantFantasyNameStorage;
    }

    return '';
  });

  // const [restaurantAvatar, setRestaurantAvatar] = useState(() => {
  //   const restaurantAvatarStorage = localStorage.getItem(
  //     '@qrexpress:restaurantAvatar',
  //   );

  //   if (restaurantAvatarStorage) {
  //     return restaurantAvatarStorage;
  //   }

  //   return '';
  // });

  const [restaurantWhatsapp, setRestaurantWhatsapp] = useState(() => {
    const restaurantWhatsappStorage = localStorage.getItem(
      '@qrexpress:restaurantWhatsapp',
    );

    if (restaurantWhatsappStorage) {
      return restaurantWhatsappStorage;
    }

    return '';
  });

  const [shoppingWasRated, setShoppingWasRated] = useState(() => {
    const shoppingWasRatedStorage = localStorage.getItem(
      '@qrexpress:shoppingWasRated',
    );

    if (shoppingWasRatedStorage) {
      return shoppingWasRatedStorage;
    }

    return '';
  });

  const [billId, setBillId] = useState(() => {
    const billIdStorage = localStorage.getItem('@qrexpress:billId');

    if (billIdStorage) {
      return billIdStorage;
    }

    return '';
  });

  const [basketId, setBasketId] = useState(() => {
    const basketIdStorage = localStorage.getItem('@qrexpress:basketId');

    if (basketIdStorage) {
      return basketIdStorage;
    }

    return '';
  });

  const [orderBasketId, setOrderBasketId] = useState(() => {
    const orderBasketIdStorage = localStorage.getItem(
      '@qrexpress:orderBasketId',
    );

    if (orderBasketIdStorage) {
      return orderBasketIdStorage;
    }

    return '';
  });

  const [restaurantAcceptsPicpay, setRestaurantAcceptsPicpay] = useState(() => {
    const restaurantAcceptsPicpayStorage = localStorage.getItem(
      '@qrexpress:restaurantAcceptsPicpay',
    );

    if (restaurantAcceptsPicpayStorage) {
      return restaurantAcceptsPicpayStorage;
    }

    return '';
  });

  const [restaurantAcceptsPaytime, setRestaurantAcceptsPaytime] = useState(
    () => {
      const restaurantAcceptsPaytimeStorage = localStorage.getItem(
        '@qrexpress:restaurantAcceptsPaytime',
      );

      if (restaurantAcceptsPaytimeStorage) {
        return restaurantAcceptsPaytimeStorage;
      }

      return '';
    },
  );

  const [
    restaurantAcceptsWithdrawal,
    setRestaurantAcceptsWithdrawal,
  ] = useState(() => {
    const restaurantAcceptsWithdrawalStorage = localStorage.getItem(
      '@qrexpress:restaurantAcceptsWithdrawal',
    );

    if (restaurantAcceptsWithdrawalStorage) {
      return restaurantAcceptsWithdrawalStorage;
    }

    return '';
  });

  const [restaurantAcceptsDelivery, setRestaurantAcceptsDelivery] = useState(
    () => {
      const restaurantAcceptsDeliveryStorage = localStorage.getItem(
        '@qrexpress:restaurantAcceptsDelivery',
      );

      if (restaurantAcceptsDeliveryStorage) {
        return restaurantAcceptsDeliveryStorage;
      }

      return '';
    },
  );

  const [restaurantDeliveryActive, setRestaurantDeliveryActive] = useState(
    () => {
      const restaurantDeliveryActiveStorage = localStorage.getItem(
        '@qrexpress:restaurantDeliveryActive',
      );

      if (restaurantDeliveryActiveStorage) {
        return restaurantDeliveryActiveStorage;
      }

      return '';
    },
  );

  const [restaurantDeliveryTime, setRestaurantDeliveryTime] = useState(() => {
    const restaurantDeliveryTimeStorage = localStorage.getItem(
      '@qrexpress:restaurantDeliveryTime',
    );

    if (restaurantDeliveryTimeStorage) {
      return restaurantDeliveryTimeStorage;
    }

    return '';
  });

  const [restaurantWithdrawalTime, setRestaurantWithdrawalTime] = useState(
    () => {
      const restaurantWithdrawalTimeStorage = localStorage.getItem(
        '@qrexpress:restaurantWithdrawalTime',
      );

      if (restaurantWithdrawalTimeStorage) {
        return restaurantWithdrawalTimeStorage;
      }

      return '';
    },
  );

  const [restaurantIsOpen, setRestaurantIsOpen] = useState(() => {
    const restaurantIsOpenStorage = localStorage.getItem(
      '@qrexpress:restaurantIsOpen',
    );

    if (restaurantIsOpenStorage) {
      return restaurantIsOpenStorage;
    }

    return '';
  });

  const [restaurantProductPromotion, setRestaurantProductPromotion] = useState(
    () => {
      const restaurantProductPromotionStorage = localStorage.getItem(
        '@qrexpress:restaurantProductPromotion',
      );

      if (restaurantProductPromotionStorage) {
        if (restaurantProductPromotionStorage.toString() === 'undefined') {
          return null;
        }
      }

      if (restaurantProductPromotionStorage) {
        return JSON.parse(restaurantProductPromotionStorage);
      }

      return null;
    },
  );

  const [restaurantCouponCode, setRestaurantCouponCode] = useState(() => {
    const restaurantCouponCodeStorage = localStorage.getItem(
      '@qrexpress:restaurantCouponCode',
    );

    if (restaurantCouponCodeStorage) {
      return restaurantCouponCodeStorage;
    }

    return '';
  });

  const [userDeliveryType, setUserDeliveryType] = useState(() => {
    const userDeliveryTypeStorage = localStorage.getItem(
      '@qrexpress:userDeliveryType',
    );

    if (userDeliveryTypeStorage) {
      return userDeliveryTypeStorage;
    }

    return '';
  });

  const [userAddressId, setUserAddressId] = useState(() => {
    const userAddressIdStorage = localStorage.getItem(
      '@qrexpress:userAddressId',
    );

    if (userAddressIdStorage) {
      return userAddressIdStorage;
    }

    return '';
  });

  const [userAddress, setUserAddress] = useState(() => {
    const userAddressStorage = localStorage.getItem('@qrexpress:userAddress');

    if (userAddressStorage) {
      return JSON.parse(userAddressStorage);
    }

    return '';
  });

  const [restaurantDeliveryPayments, setRestaurantDeliveryPayments] = useState(
    () => {
      const restaurantDeliveryPaymentsStorage = localStorage.getItem(
        '@qrexpress:restaurantDeliveryPayments',
      );

      if (restaurantDeliveryPaymentsStorage) {
        return JSON.parse(restaurantDeliveryPaymentsStorage);
      }

      return '';
    },
  );

  const [restaurantDeliveryTax, setRestaurantDeliveryTax] = useState(() => {
    const restaurantDeliveryTaxStorage = localStorage.getItem(
      '@qrexpress:restaurantDeliveryTax',
    );

    if (restaurantDeliveryTaxStorage) {
      return restaurantDeliveryTaxStorage;
    }

    return '';
  });

  const [
    restaurantDeliveryMinimumPrice,
    setRestaurantDeliveryMinimumPrice,
  ] = useState(() => {
    const restaurantDeliveryMinimumPriceStorage = localStorage.getItem(
      '@qrexpress:restaurantDeliveryMinimumPrice',
    );

    if (restaurantDeliveryMinimumPriceStorage) {
      return restaurantDeliveryMinimumPriceStorage;
    }

    return '';
  });

  const [restaurantAddress, setRestaurantAddress] = useState(() => {
    const restaurantAddressStorage = localStorage.getItem(
      '@qrexpress:restaurantAddress',
    );

    if (restaurantAddressStorage) {
      return restaurantAddressStorage;
    }

    return '';
  });

  const [isDeliveryByDistance, setIsDeliveryByDistance] = useState(() => {
    const isDeliveryByDistanceStorage = localStorage.getItem(
      '@qrexpress:isDeliveryByDistance',
    );

    if (isDeliveryByDistanceStorage) {
      return isDeliveryByDistanceStorage;
    }

    return '';
  });

  const [userChange, setUserChange] = useState(() => {
    const userChangeStorage = localStorage.getItem('@qrexpress:userChange');

    if (userChangeStorage) {
      return userChangeStorage;
    }

    return '';
  });

  const [isUserPodi, setIsUserPodi] = useState(() => {
    const isUserPodiStorage = localStorage.getItem('@qrexpress:isUserPodi');

    if (isUserPodiStorage) {
      return isUserPodiStorage;
    }

    return '';
  });

  useEffect(() => {
    localStorage.setItem('@qrexpress:isUserPodi', isUserPodi);
  }, [isUserPodi]);

  useEffect(() => {
    localStorage.setItem('@qrexpress:userChange', userChange);
  }, [userChange]);

  useEffect(() => {
    localStorage.setItem(
      '@qrexpress:isDeliveryByDistance',
      isDeliveryByDistance,
    );
  }, [isDeliveryByDistance]);

  useEffect(() => {
    localStorage.setItem('@qrexpress:restaurantAddress', restaurantAddress);
  }, [restaurantAddress]);

  useEffect(() => {
    localStorage.setItem(
      '@qrexpress:restaurantDeliveryMinimumPrice',
      restaurantDeliveryMinimumPrice,
    );
  }, [restaurantDeliveryMinimumPrice]);

  useEffect(() => {
    localStorage.setItem(
      '@qrexpress:restaurantDeliveryPayments',
      JSON.stringify(restaurantDeliveryPayments),
    );
  }, [restaurantDeliveryPayments]);

  useEffect(() => {
    localStorage.setItem(
      '@qrexpress:restaurantDeliveryTax',
      restaurantDeliveryTax,
    );
  }, [restaurantDeliveryTax]);

  useEffect(() => {
    localStorage.setItem('@qrexpress:userAddress', JSON.stringify(userAddress));
  }, [userAddress]);

  useEffect(() => {
    localStorage.setItem('@qrexpress:userAddressId', userAddressId);
  }, [userAddressId]);

  useEffect(() => {
    localStorage.setItem('@qrexpress:userDeliveryType', userDeliveryType);
  }, [userDeliveryType]);

  useEffect(() => {
    localStorage.setItem(
      '@qrexpress:restaurantCouponCode',
      restaurantCouponCode,
    );
  }, [restaurantCouponCode]);

  useEffect(() => {
    localStorage.setItem(
      '@qrexpress:restaurantProductPromotion',
      JSON.stringify(restaurantProductPromotion),
    );
  }, [restaurantProductPromotion]);

  useEffect(() => {
    localStorage.setItem('@qrexpress:restaurantIsOpen', restaurantIsOpen);
  }, [restaurantIsOpen]);

  useEffect(() => {
    localStorage.setItem('@qrexpress:restaurantUsername', restaurantUsername);
  }, [restaurantUsername]);

  useEffect(() => {
    localStorage.setItem(
      '@qrexpress:restaurantDeliveryTime',
      restaurantDeliveryTime,
    );
  }, [restaurantDeliveryTime]);

  useEffect(() => {
    localStorage.setItem(
      '@qrexpress:restaurantWithdrawalTime',
      restaurantWithdrawalTime,
    );
  }, [restaurantWithdrawalTime]);

  useEffect(() => {
    localStorage.setItem(
      '@qrexpress:restaurantAcceptsPicpay',
      restaurantAcceptsPicpay,
    );
  }, [restaurantAcceptsPicpay]);

  useEffect(() => {
    localStorage.setItem(
      '@qrexpress:restaurantAcceptsPaytime',
      restaurantAcceptsPaytime,
    );
  }, [restaurantAcceptsPaytime]);

  useEffect(() => {
    localStorage.setItem(
      '@qrexpress:restaurantAcceptsWithdrawal',
      restaurantAcceptsWithdrawal,
    );
  }, [restaurantAcceptsWithdrawal]);

  useEffect(() => {
    localStorage.setItem(
      '@qrexpress:restaurantAcceptsDelivery',
      restaurantAcceptsDelivery,
    );
  }, [restaurantAcceptsDelivery]);

  useEffect(() => {
    localStorage.setItem(
      '@qrexpress:restaurantDeliveryActive',
      restaurantDeliveryActive,
    );
  }, [restaurantDeliveryActive]);

  useEffect(() => {
    localStorage.setItem('@qrexpress:shoppingWasRated', shoppingWasRated);
  }, [shoppingWasRated]);

  useEffect(() => {
    localStorage.setItem('@qrexpress:orderPicPayId', orderPicPayId);
  }, [orderPicPayId]);

  useEffect(() => {
    localStorage.setItem('@qrexpress:basketId', basketId);
  }, [basketId]);

  useEffect(() => {
    localStorage.setItem('@qrexpress:orderBasketId', orderBasketId);
  }, [orderBasketId]);

  useEffect(() => {
    localStorage.setItem('@qrexpress:billId', billId);
  }, [billId]);

  useEffect(() => {
    localStorage.setItem('@qrexpress:userPhone', userPhone);
  }, [userPhone]);

  useEffect(() => {
    localStorage.setItem('@qrexpress:userToken', userToken);
  }, [userToken]);

  useEffect(() => {
    localStorage.setItem('@qrexpress:userFirstName', userFirstName);
  }, [userFirstName]);

  useEffect(() => {
    localStorage.setItem('@qrexpress:userLastName', userLastName);
  }, [userLastName]);

  useEffect(() => {
    localStorage.setItem('@qrexpress:userEmail', userEmail);
  }, [userEmail]);

  useEffect(() => {
    localStorage.setItem('@qrexpress:userChosePaySms', userChosePaySms);
  }, [userChosePaySms]);

  useEffect(() => {
    localStorage.setItem('@qrexpress:clientPaySms', clientPaySms);
  }, [clientPaySms]);

  useEffect(() => {
    localStorage.setItem(
      '@qrexpress:restaurantHasSmsService',
      restaurantHasSmsService,
    );
  }, [restaurantHasSmsService]);

  useEffect(() => {
    localStorage.setItem(
      '@qrexpress:isSmsServiceOptional',
      isSmsServiceOptional,
    );
  }, [isSmsServiceOptional]);

  useEffect(() => {
    localStorage.setItem(
      '@qrexpress:userPaymentMethod',
      JSON.stringify(userPaymentMethod),
    );
  }, [userPaymentMethod]);

  useEffect(() => {
    localStorage.setItem('@qrexpress:picpayUrlPayment', picpayUrlPayment);
  }, [picpayUrlPayment]);

  useEffect(() => {
    localStorage.setItem('@qrexpress:picpayReferenceId', picpayReferenceId);
  }, [picpayReferenceId]);

  useEffect(() => {
    localStorage.setItem('@qrexpress:restaurantId', restaurantId);
  }, [restaurantId]);

  useEffect(() => {
    localStorage.setItem('@qrexpress:restaurantWhatsapp', restaurantWhatsapp);
  }, [restaurantWhatsapp]);

  useEffect(() => {
    localStorage.setItem('@qrexpress:shoppingName', shoppingName);
  }, [shoppingName]);

  useEffect(() => {
    localStorage.setItem('@qrexpress:shoppingId', shoppingId);
  }, [shoppingId]);

  useEffect(() => {
    localStorage.setItem(
      '@qrexpress:restaurantFantasyName',
      restaurantFantasyName,
    );
  }, [restaurantFantasyName]);

  // useEffect(() => {
  //   localStorage.setItem('@qrexpress:restaurantAvatar', restaurantAvatar);
  // }, [restaurantAvatar]);

  const setUserPhone = useCallback(number => {
    setUserphone(number);
  }, []);

  const setShopping = useCallback((id, name) => {
    setShoppingId(id);
    setShoppingName(name);
  }, []);

  const setUser = useCallback(
    (firstName, lastName, phone, email) => {
      setUserPhone(phone);
      setUserFirstName(firstName);
      setUserLastName(lastName);
      setUserEmail(email);
    },
    [setUserPhone, setUserFirstName, setUserLastName, setUserEmail],
  );

  const podiLogin = useCallback(token => {
    api.defaults.headers.Authorization = `Bearer ${token}`;

    setUserToken(token);
  }, []);

  const userLogin = useCallback(async (phone, firstName, lastName, email) => {
    const res = await api.get(`public/buyers?phone=${phone}`);

    if (!res.data) {
      await api.post(`public/buyers`, {
        phone,
        name: `${firstName} ${lastName}`,
        email,
      });
    }

    const response = await api.post('public/sessions/client', { phone });

    const { token } = response.data;

    if (!token) {
      console.log('Erro no login, token nao criado');
    }

    api.defaults.headers.Authorization = `Bearer ${token}`;

    setUserToken(token);

    await api.put('client/buyers', {
      name: `${firstName} ${lastName}`,
      email,
    });
  }, []);

  const setSmsOptions = useCallback(
    (client_pay, is_sms_optional, has_sms_service) => {
      setClientPaySms(client_pay);
      setIsSmsServiceOptional(is_sms_optional);
      setRestaurantHasSmsService(has_sms_service);
    },
    [],
  );

  function promotionLogOut() {
    setRestaurantProductPromotion(null);
  }

  const logOut = useCallback(() => {
    localStorage.removeItem('@qrexpress:userPhone');
    localStorage.removeItem('@qrexpress:userToken');

    localStorage.removeItem('@qrexpress:restaurantFantasyName');
    setUserphone('');
    setUserToken('');

    setRestaurantId('');

    setRestaurantFantasyName('');
  }, []);

  const userLogout = useCallback(() => {
    localStorage.removeItem('@qrexpress:userPhone');
    localStorage.removeItem('@qrexpress:userFirstName');
    localStorage.removeItem('@qrexpress:userLastName');
    localStorage.removeItem('@qrexpress:userEmail');
    localStorage.removeItem('@qrexpress:userToken');

    setUserphone('');
    setUserFirstName('');
    setUserLastName('');
    setUserEmail('');
    setUserToken('');
  }, []);

  return (
    <AuthContext.Provider
      value={{
        setUserPhone,
        userPhone,
        setUser,
        logOut,
        userLogin,
        userEmail,
        promotionLogOut,
        userChosePaySms,
        setUserChosePaySms,
        clientPaySms,
        setSmsOptions,
        restaurantHasSmsService,
        shoppingId,
        setShoppingWasRated,
        shoppingWasRated,
        isSmsServiceOptional,
        restaurantWhatsapp,
        setRestaurantWhatsapp,
        userPaymentMethod,
        picpayUrlPayment,
        picpayReferenceId,
        setPicpayReferenceId,
        restaurantId,
        setRestaurantId,
        setRestaurantFantasyName,
        restaurantFantasyName,
        setRestaurantUsername,
        restaurantUsername,
        shoppingName,
        setShopping,
        userLogout,
        setBillId,
        billId,
        setBasketId,
        basketId,
        setUserPaymentMethod,
        setOrderPicPayId,
        setPicpayUrlPayment,
        userToken,
        orderBasketId,
        setOrderBasketId,
        setRestaurantAcceptsPaytime,
        setRestaurantAcceptsPicpay,
        restaurantAcceptsPaytime,
        restaurantAcceptsPicpay,
        restaurantIsOpen,
        setRestaurantIsOpen,
        setRestaurantProductPromotion,
        restaurantProductPromotion,
        setRestaurantCouponCode,
        restaurantCouponCode,
        restaurantAcceptsWithdrawal,
        setRestaurantAcceptsWithdrawal,
        restaurantAcceptsDelivery,
        setRestaurantAcceptsDelivery,
        restaurantDeliveryMinimumPrice,
        setRestaurantDeliveryMinimumPrice,
        restaurantDeliveryActive,
        setRestaurantDeliveryActive,
        userDeliveryType,
        setUserDeliveryType,
        userAddressId,
        setUserAddressId,
        userAddress,
        setUserAddress,
        restaurantDeliveryPayments,
        setRestaurantDeliveryPayments,
        restaurantDeliveryTax,
        setRestaurantDeliveryTax,
        restaurantDeliveryTime,
        setRestaurantDeliveryTime,
        restaurantWithdrawalTime,
        setRestaurantWithdrawalTime,
        userChange,
        setUserChange,
        isDeliveryByDistance,
        setIsDeliveryByDistance,
        restaurantAddress,
        setRestaurantAddress,
        isUserPodi,
        setIsUserPodi,
        podiLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    console.log('useAuth must be within a provider');
  }

  return context;
}

export { AuthProvider, useAuth };
