import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  background: #f8f8f8;
  width: 100%;
  max-width: 410px;

  padding: 10px;
`;

export const NoCoupons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  padding-top: 25%;

  h1 {
    font-size: 16px;
    text-align: center;
    margin-top: 16px;
  }
  img {
    width: 200px;
  }
`;

export const LoadingCoupons = styled.div`
  margin-top: 50%;

  h1 {
    text-align: center;
    font-size: 20px;
  }
`;
