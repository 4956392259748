import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-scroll';
import ScrollContainer from 'react-indiana-drag-scroll';
import Modal from 'react-modal';

import {
  FiChevronDown,
  FiArrowLeft,
  FiClock,
  FiMessageCircle,
  FiShoppingBag,
} from 'react-icons/fi';
import ScrollToTop from '../../components/ScrollToTop';

import { useCart } from '../../context/cart';
import { useAuth } from '../../context/auth';

import takeatLogo from '../../assets/images/takeatLogoRosa.svg';

import api from '../../services/api';
import formatValue from '../../utils/formatValue';

import fotoPadrao from '../../assets/images/fotoPadrao.png';
import logoIcon from '../../assets/images/logo_icon.png';
import {
  ProductsEmpty,
  ButtonFoodcourt,
  ButtonWhatsapp,
  Container,
  Content,
  MenuContent,
  MenuItem,
  RestaurantStatus,
  MenuItemHeader,
  MenuItemBody,
  MenuItemDescription,
  MenuItemPrice,
  MenuItemPromotion,
  Badge,
  HeaderAction,
  AddToCart,
  Footer,
  ButtonGoToCart,
  CountBadge,
  ButtonAction,
  SoldOff,
  LoadingMenu,
  ProductPromotion,
  ModalFooter,
  ModalTitle,
  ConfirmButton,
} from './styles';

const Menu = props => {
  const { cart } = useCart();
  const {
    tableNumber,
    restaurantFantasyName,
    shoppingName,
    userToken,
    restaurantWhatsapp,
    restaurantIsOpen,
    restaurantProductPromotion,
    setRestaurantCouponCode,
    setUserDeliveryType,
    userLogout,
    setIsUserPodi,
    setRestaurantId,
    podiLogin,
    setRestaurantFantasyName,
    setSmsOptions,
    setRestaurantWhatsapp,
    setRestaurantAcceptsPicpay,
    setRestaurantAcceptsPaytime,
    setRestaurantIsOpen,
    setRestaurantAcceptsWithdrawal,
    setRestaurantAcceptsDelivery,
    setRestaurantDeliveryActive,
    setRestaurantDeliveryPayments,
    setRestaurantDeliveryTime,
    setRestaurantWithdrawalTime,
    setRestaurantDeliveryMinimumPrice,
    setIsDeliveryByDistance,
    setRestaurantAddress,
    setPicpayReferenceId,
  } = useAuth();
  const [menu, setMenu] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalPhrase, setModalPhrase] = useState('');
  const [restaurantName, setRestaurantName] = useState('');
  const { params } = props.match;
  const { is_podi, user_token, restaurant_id } = params;
  const history = useHistory();

  const [modalIsOpen, setIsOpen] = useState(false);
  Modal.setAppElement('#root');

  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '7px',
    },
  };

  function closeModal() {
    setIsOpen(false);
  }

  function openModal(phrase) {
    setIsOpen(true);
    setModalPhrase(phrase);
  }

  const verifyToken = async () => {
    try {
      await api.get('/client/verify/session');
      console.log('Token Ok');
    } catch (err) {
      if (!err.response.ok) {
        switch (err.response.data.errorType) {
          case 'invalid_token':
            console.log(err.message);
            userLogout();
        }
      }
    }
  };

  useEffect(() => {
    verifyToken();
  }, []);

  useEffect(() => {
    setRestaurantCouponCode('');
    setPicpayReferenceId('');
    setUserDeliveryType('');
  }, [setRestaurantCouponCode, setUserDeliveryType, setPicpayReferenceId]);

  useEffect(() => {
    async function getMenu() {
      try {
        const response = await api.get(
          `public/restaurants/menu/${restaurant_id}`,
        );
        setMenu(response.data);

        const userType = is_podi;
        const userPodiToken = user_token;
        const restaurantId = restaurant_id;

        if (userType !== 'takeat') {
          setIsUserPodi(userType);
          setRestaurantId(restaurantId);
        }

        if (userType === 'takeat') {
          setIsUserPodi(userType);
        }

        if (userPodiToken !== 'restaurant') {
          podiLogin(userPodiToken);
        }

        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    }

    getMenu();
  }, [
    restaurant_id,
    history,
    tableNumber,
    is_podi,
    user_token,
    podiLogin,
    setIsUserPodi,
    setRestaurantId,
  ]);

  const getRestaurantData = async () => {
    const resp = await api.get(`/public/restaurant-by-id/${restaurant_id}`);

    if (is_podi === 'podi') {
      console.log(resp.data);

      const {
        id,
        client_pay_sms,
        has_paytime_service,
        has_picpay_service,
        has_sms_service,
        withdrawal_allowed,
        is_sms_service_optional,
        fantasy_name,
        opened,
        whatsapp,
        avatar,
        is_delivery_allowed,
        is_delivery_active,
        payment_methods,
        time_to_delivery,
        time_to_withdrawal,
        minimum_delivery_price,
        is_delivery_by_distance,
        address,
      } = resp.data;

      setRestaurantName(fantasy_name);

      if (whatsapp) {
        const restaurantWhatsappData = whatsapp
          .replace('(', '')
          .replace(')', '')
          .replace(' ', '')
          .replace(' ', '')
          .replace('+', '')
          .replace('-', '');

        setRestaurantWhatsapp(restaurantWhatsappData);
      } else {
        setRestaurantWhatsapp('');
      }

      setRestaurantId(id);
      setRestaurantFantasyName(fantasy_name);
      setSmsOptions(client_pay_sms, is_sms_service_optional, has_sms_service);
      setRestaurantAcceptsPicpay(has_picpay_service);
      setRestaurantAcceptsPaytime(has_paytime_service);
      setRestaurantIsOpen(opened);
      setRestaurantAcceptsWithdrawal(withdrawal_allowed);
      setRestaurantAcceptsDelivery(is_delivery_allowed || false);
      setRestaurantDeliveryActive(is_delivery_active || false);
      setRestaurantDeliveryPayments(payment_methods || null);
      setRestaurantDeliveryTime(time_to_delivery || null);
      setRestaurantWithdrawalTime(time_to_withdrawal || null);
      setRestaurantDeliveryMinimumPrice(minimum_delivery_price || null);
      setIsDeliveryByDistance(is_delivery_by_distance || '');
      setRestaurantAddress(address ? address.state : null);
    }
  };

  useEffect(() => {
    try {
      getRestaurantData();
    } catch (error) {
      console.log('erro ao carregar informções');
    }
  }, [userToken]);

  function handleGoToCart() {
    if (cart.length < 1) {
      openModal('Seu carrinho ainda está vazio.');
    } else {
      history.push('/cart');
    }
  }

  function handleOpenWhatsapp() {
    if (restaurantWhatsapp) {
      window.open(`https://wa.me/${restaurantWhatsapp}`);
    } else {
      openModal(
        'Este Restaurante ainda não possui o serviço de Chat, favor comparecer ao balcão.',
      );
    }
  }

  function handleGoToOrders() {
    const userType = is_podi;
    const userPodiToken = user_token;
    const restaurantId = restaurant_id;

    if (userType === 'takeat') {
      if (!userToken) {
        openModal('Você ainda não fez pedidos.');
      } else {
        history.push('/orders');
      }
    }

    if (userType === 'podi') {
      window.location.href = 'https://app.podiapp.com.br/app/meus_pagamentos';
    }
  }

  return isLoading ? (
    <LoadingMenu>
      <img src={takeatLogo} alt="Logo Takeat" />
      <h1>Carregando Cardápio ...</h1>
    </LoadingMenu>
  ) : (
    <Container>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <p>{modalPhrase}</p>
        </ModalTitle>
        <ModalFooter>
          <ConfirmButton onClick={closeModal}>Ok, entendi</ConfirmButton>
        </ModalFooter>
      </Modal>
      <Content>
        <ScrollToTop />
        {restaurantIsOpen.toString() === 'false' ? (
          <RestaurantStatus>
            <p>Restaurante fechado</p>
          </RestaurantStatus>
        ) : (
          ''
        )}

        <HeaderAction>
          <header>
            {is_podi === 'takeat' && (
              <FiArrowLeft
                color="#333"
                onClick={() => {
                  history.push(`/qrexpress/${shoppingName}`);
                }}
              />
            )}

            {is_podi === 'takeat' ? (
              <h1>{restaurantFantasyName}</h1>
            ) : (
              <h1>{restaurantName}</h1>
            )}
          </header>
          <nav>
            <ScrollContainer className="scroll-container">
              {menu.map(category => (
                <React.Fragment key={category.id}>
                  {category.products.length > 0 && (
                    <li>
                      <Link
                        className="styled-links"
                        to={category.name}
                        spy
                        smooth
                        duration={900}
                        offset={-100}
                      >
                        {category.name}
                      </Link>
                    </li>
                  )}
                </React.Fragment>
              ))}
            </ScrollContainer>
          </nav>
        </HeaderAction>
        {!!restaurantProductPromotion && (
          <ProductPromotion>
            <MenuItemBody key={restaurantProductPromotion.product.id}>
              {restaurantProductPromotion.product.price_promotion ? (
                <AddToCart
                  disable={restaurantProductPromotion.product.sold_off.toString()}
                  to={{
                    pathname: '/product',
                    state: {
                      item: {
                        ...restaurantProductPromotion.product,
                        price:
                          restaurantProductPromotion.product.price_promotion,
                      },
                    },
                  }}
                >
                  <div>
                    {restaurantProductPromotion.product.image && (
                      <img
                        src={
                          restaurantProductPromotion.product.image.url ||
                          fotoPadrao
                        }
                        alt={restaurantProductPromotion.product.nome || 'foto'}
                        style={{ width: 68 }}
                      />
                    )}

                    <MenuItemDescription
                      disable={restaurantProductPromotion.product.sold_off.toString()}
                    >
                      <strong>{restaurantProductPromotion.product.name}</strong>
                      <p>{restaurantProductPromotion.product.description}</p>
                    </MenuItemDescription>
                  </div>

                  <MenuItemPrice>
                    {restaurantProductPromotion.product.price_promotion ? (
                      <>
                        <MenuItemPromotion>
                          {formatValue(
                            restaurantProductPromotion.product.price,
                          )}
                        </MenuItemPromotion>
                        <p>
                          {formatValue(
                            restaurantProductPromotion.product.price_promotion,
                          )}
                        </p>
                      </>
                    ) : (
                      <p>
                        {formatValue(restaurantProductPromotion.product.price)}
                      </p>
                    )}

                    {restaurantProductPromotion.product.promotion && (
                      <Badge>
                        {restaurantProductPromotion.product.promotion}
                      </Badge>
                    )}
                    {restaurantProductPromotion.product.sold_off && (
                      <SoldOff>Esgotado</SoldOff>
                    )}
                  </MenuItemPrice>
                </AddToCart>
              ) : (
                <AddToCart
                  disable={restaurantProductPromotion.product.sold_off.toString()}
                  to={{
                    pathname: '/product',
                    state: { item: { ...restaurantProductPromotion.product } },
                  }}
                >
                  <div>
                    {restaurantProductPromotion.product.image && (
                      <img
                        src={
                          restaurantProductPromotion.product.image.url ||
                          fotoPadrao
                        }
                        alt={restaurantProductPromotion.product.nome || 'foto'}
                        style={{ width: 68 }}
                      />
                    )}

                    <MenuItemDescription
                      disable={restaurantProductPromotion.product.sold_off.toString()}
                    >
                      <strong>{restaurantProductPromotion.product.name}</strong>
                      <p>{restaurantProductPromotion.product.description}</p>
                    </MenuItemDescription>
                  </div>

                  <MenuItemPrice>
                    {restaurantProductPromotion.product.price_promotion ? (
                      <>
                        <MenuItemPromotion>
                          {formatValue(
                            restaurantProductPromotion.product.price,
                          )}
                        </MenuItemPromotion>
                        <p>
                          {formatValue(
                            restaurantProductPromotion.product.price_promotion,
                          )}
                        </p>
                      </>
                    ) : (
                      <p>
                        {formatValue(restaurantProductPromotion.product.price)}
                      </p>
                    )}

                    {restaurantProductPromotion.product.promotion && (
                      <Badge>
                        {restaurantProductPromotion.product.promotion}
                      </Badge>
                    )}
                    {restaurantProductPromotion.product.sold_off && (
                      <SoldOff>Esgotado</SoldOff>
                    )}
                  </MenuItemPrice>
                </AddToCart>
              )}
            </MenuItemBody>
          </ProductPromotion>
        )}
        {menu.length > 0 ? (
          <MenuContent>
            {menu.map(category => (
              <React.Fragment key={category.id}>
                {category.products.length >= 1 && (
                  <MenuItem>
                    <MenuItemHeader name={category.name}>
                      <strong>{category.name}</strong>
                      <div>
                        {category.preparation_time > 0 && (
                          <span>
                            <FiClock />
                            <p>
{' '}
{category.preparation_time} min.</p>
                          </span>
                        )}
                        <FiChevronDown size={10} color="#333" />
                      </div>
                    </MenuItemHeader>
                    {category.products.map(item => (
                      <MenuItemBody key={item.id}>
                        {item.price_promotion ? (
                          <AddToCart
                            disable={item.sold_off.toString()}
                            to={{
                              pathname: '/product',
                              state: {
                                item: { ...item, price: item.price_promotion },
                              },
                            }}
                          >
                            <div>
                              {item.image && (
                                <img
                                  src={item.image.url || fotoPadrao}
                                  alt={item.nome || 'foto'}
                                  style={{ width: 68 }}
                                />
                              )}

                              <MenuItemDescription
                                disable={item.sold_off.toString()}
                              >
                                <strong>{item.name}</strong>
                                <p>{item.description}</p>
                              </MenuItemDescription>
                            </div>

                            <MenuItemPrice>
                              {item.price_promotion ? (
                                <>
                                  <MenuItemPromotion>
                                    {formatValue(item.price)}
                                  </MenuItemPromotion>
                                  <p>{formatValue(item.price_promotion)}</p>
                                </>
                              ) : (
                                <p>{formatValue(item.price)}</p>
                              )}

                              {item.promotion && (
                                <Badge>{item.promotion}</Badge>
                              )}
                              {item.sold_off && <SoldOff>Esgotado</SoldOff>}
                            </MenuItemPrice>
                          </AddToCart>
                        ) : (
                          <AddToCart
                            disable={item.sold_off.toString()}
                            to={{
                              pathname: '/product',
                              state: { item },
                            }}
                          >
                            <div>
                              {item.image && (
                                <img
                                  src={item.image.url || fotoPadrao}
                                  alt={item.nome || 'foto'}
                                  style={{ width: 68 }}
                                />
                              )}

                              <MenuItemDescription
                                disable={item.sold_off.toString()}
                              >
                                <strong>{item.name}</strong>
                                <p>{item.description}</p>
                              </MenuItemDescription>
                            </div>

                            <MenuItemPrice>
                              {item.price_promotion ? (
                                <>
                                  <MenuItemPromotion>
                                    {formatValue(item.price)}
                                  </MenuItemPromotion>
                                  <p>{formatValue(item.price_promotion)}</p>
                                </>
                              ) : (
                                <p>{formatValue(item.price)}</p>
                              )}

                              {item.promotion && (
                                <Badge>{item.promotion}</Badge>
                              )}
                              {item.sold_off && <SoldOff>Esgotado</SoldOff>}
                            </MenuItemPrice>
                          </AddToCart>
                        )}
                      </MenuItemBody>
                    ))}
                  </MenuItem>
                )}
              </React.Fragment>
            ))}
          </MenuContent>
        ) : (
          <ProductsEmpty>
            <h1>
              Este restaurante
{' '}
<br />
              {' '}
              está com o cardápio vazio.
</h1>
          </ProductsEmpty>
        )}

        <Footer>
          <div>
            <ButtonFoodcourt onClick={handleGoToOrders}>
              <FiShoppingBag size={20} color="#fb4a20" />
              Meus Pedidos
            </ButtonFoodcourt>
            <ButtonAction>
              <ButtonGoToCart onClick={handleGoToCart}>
                <img src={logoIcon} alt="logo da takeat" />
                {!!cart.length > 0 && <CountBadge>{cart.length}</CountBadge>}
              </ButtonGoToCart>
              <span>Pedir</span>
            </ButtonAction>
            <ButtonWhatsapp onClick={handleOpenWhatsapp}>
              <FiMessageCircle size={20} color="#fb4a20" />
              Fale Consoco
            </ButtonWhatsapp>
          </div>
        </Footer>
      </Content>
    </Container>
  );
};

export default Menu;
