import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 414px;

  display: flex;
  flex-direction: column;
  padding-bottom: 64px;

  a {
    text-decoration: none;
    color: #fb4a20;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-weight: 700;
  }

  footer {
    width: 100%;
    max-width: 410px;

    padding-left: 10px;
    padding-right: 10px;

    max-width: 414px;
    height: 65px;
    background: #fff;
    position: fixed;
    bottom: 0;

    border-top: 1px solid #33333310;

    > div {
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-family: Arial, Helvetica, sans-serif;
        margin-left: 8px;
      }
    }
  }
`;

export const HeaderAction = styled.div`
  width: 100%;
  position: fixed;

  padding-left: 10px;

  top: 0;
  padding-top: 14px;
  background: #fff;
`;

export const ImageProduct = styled.div`
  margin-top: 48px;
  /* margin-right: -20px;
  margin-left: -20px; */

  img {
    width: 100%;
  }
`;

export const DescriptionProduct = styled.div`
  padding: 10px;

  h1 {
    margin-top: 40px;
    font-size: 20px;
    font-weight: 700;
  }

  p {
    font-size: 14px;
    margin-top: 8px;
  }
`;

export const AddItemsProduct = styled.div`
  padding-right: 10px;
  padding-left: 10px;

  margin-top: 24px;
  padding-bottom: 30px;
  div {
    li {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 10px;

      & + li {
        border-top: 1px solid #33333320;
      }
    }
  }
`;

export const EditAction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const EditActionButtons = styled.div`
  width: 100px;
  justify-content: space-around;
  align-items: center;
  display: flex;
  color: #fb4a20;
  font-size: 24px;
  font-family: Arial, Helvetica, sans-serif;

  button {
    border: 0;
    background: transparent;
  }
`;

export const ComplementsHeaderProduct = styled.div`
  margin-top: 25px;
  background: #f8f8f8;
  border-radius: 7px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    span {
      font-size: 12px;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
    }
  }

  > p {
    font-size: 10px;
    background: #fb4a20;
    color: #fff;
    border-radius: 7px;
    padding: 4px;
  }
`;

export const ComplementItem = styled.div`
  width: 100%;
  max-width: 414px;
  display: flex;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
`;

export const ComplementItemDescription = styled.div`
  span {
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
  }
`;

export const ComplementItemDescriptionLimit = styled.p`
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  color: #fb4a20;
  margin-top: 5px;
`;

export const ComplementItemAmount = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-right: 10px;
    margin-left: 10px;
    color: #fb4a20;
  }
`;

export const InformationText = styled.div`
  margin-top: 20px;

  p {
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }

  input {
    width: 100%;
    max-width: 414px;
    height: 48px;

    margin-top: 10px;
    text-align: center;

    color: #fb4a20;
    border-radius: 5px;
    border: 1px solid #fb4a20cc;

    ::placeholder {
      color: #fb4a20cc;
      font-size: 14px;
    }
  }
`;

export const ModalTitle = styled.div`
  p {
    text-align: left;
    font-weight: bold;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  margin-top: 16px;
  padding-top: 8px;
  border-top: 1px solid #33333310;
  display: flex;
  justify-content: flex-end;
`;

export const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #4ceb5b;
  border: none;
  border-radius: 7px;
  margin-right: 8px;
`;
