import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  min-height: 150px;

  display: flex;
  background: #fff;
  justify-content: space-between;
  color: #333;
  border-radius: 7px;
  padding: 8px;
  text-decoration: none;
  -webkit-box-shadow: 0px 0px 21px -17px rgba(120, 120, 120, 1);
  -moz-box-shadow: 0px 0px 21px -17px rgba(120, 120, 120, 1);
  box-shadow: 0px 0px 21px -17px rgba(120, 120, 120, 1);

  & + div {
    margin-top: 16px;
  }
`;

export const Discount = styled.div`
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-right: 1px solid #33333320;

  img {
    width: 60px;
  }
`;

export const DiscountName = styled.p`
  font-weight: bold;
  font-size: 20px;
  color: #0aa5ff;
  text-align: center;
`;

export const RestaurantName = styled.p`
  font-weight: bold;
  font-size: 12px;
  color: #333;
  text-align: center;
`;

export const CouponDetails = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 16px;
`;

export const Title = styled.p`
  font-weight: bold;
`;

export const Subtitle = styled.p`
  font-size: 14px;
  color: #333;
`;

export const CouponCode = styled.div`
  display: flex;

  justify-content: space-between;
  align-items: center;
`;

export const CodeDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  p {
    font-size: 12px;
    color: #33333380;
  }
`;

export const Code = styled.input`
  width: 100px;
  font-size: 16px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  border: none;
  color: #0aa5ff;
`;

export const ButtonCopyCode = styled.button`
  border: none;
  width: 100px;
  background-color: #0aa5ff;
  color: #fff;
  border-radius: 7px;
  text-align: center;
  padding: 10px 10px;

  font-size: 12px;
`;
