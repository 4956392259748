import React from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../context/auth';
import { useCart } from '../../context/cart';

import { Container, Content, Message, PaymentLink, Button } from './styles';

import logoPicpay from '../../assets/images/picpay-logo_BR.png';

import faltaPouco from '../../assets/images/faltaPouco.svg';

const AlmostThere = () => {
  const {
    picpayReferenceId,
    userPaymentMethod,
    isSmsServiceOptional,
    userChosePaySms,
    restaurantHasSmsService,
    userDeliveryType,
    isUserPodi,
  } = useAuth();

  const { closeCart } = useCart();

  const history = useHistory();

  function handleNavigate() {
    closeCart();

    if (isUserPodi === 'takeat') {
      history.push(`/orders`);
    } else {
      window.location.href = 'https://app.podiapp.com.br/app/meus_pagamentos';
    }
  }

  return (
    <Container>
      <Content>
        <Message>
          <img
            src={faltaPouco}
            alt="Ilustração com pessoa segurando o celular"
          />
          <h1>Agora falta pouco</h1>
          {(() => {
            if (restaurantHasSmsService.toString() === 'true') {
              if (
                (isSmsServiceOptional.toString() === 'true' &&
                  userChosePaySms.toString() === 'true') ||
                (restaurantHasSmsService.toString() === 'true' &&
                  isSmsServiceOptional.toString() === 'false')
              ) {
                return (
                  <>
                    {userPaymentMethod.name.toString() === 'picpay' &&
                      (userDeliveryType.toString() === 'withdrawal' ||
                        !userDeliveryType) && (
                        <p>
                          Assim que o pagamento for realizado e confirmado, o
                          seu pedido irá para preparo. Caso ainda não o tenha
                          efetuado, basta clicar no botão PicPay abaixo.
                          Acompanhe o status na opção Meus Pedidos. Você
                          receberá um sms assim que seu pedido ficar pronto para
                          retirada.
                        </p>
                      )}

                    {userPaymentMethod.type.toString() === 'online' &&
                      userPaymentMethod.name.toString() === 'credito' &&
                      (userDeliveryType.toString() === 'withdrawal' ||
                        !userDeliveryType) && (
                        <p>
                          Assim que o pagamento for realizado e confirmado, o
                          seu pedido irá para preparo. Acompanhe o status na
                          opção
                          <strong> Meus Pedidos</strong>
. Você receberá um sms
                          assim que seu pedido ficar pronto para retirada.
</p>
                      )}

                    {userPaymentMethod.type.toString() === 'online' &&
                      userPaymentMethod.name.toString() === 'credito' &&
                      userDeliveryType.toString() === 'delivery' && (
                        <p>
                          Assim que o pagamento for realizado e confirmado, o
                          seu pedido irá para preparo. Acompanhe o status na
                          opção
                          <strong> Meus Pedidos</strong>
. Você receberá um sms
                          assim que seu pedido sair para entrega.
</p>
                      )}

                    {userPaymentMethod.type.toString() === 'online' &&
                      userPaymentMethod.name.toString() === 'picpay' &&
                      userDeliveryType.toString() === 'delivery' && (
                        <p>
                          Assim que o pagamento for realizado e confirmado, o
                          seu pedido irá para preparo. Caso ainda não o tenha
                          efetuado, basta clicar no botão PicPay abaixo.
                          Acompanhe o status na opção Meus Pedidos. Você
                          receberá um sms assim que seu pedido sair para
                          entrega.
                        </p>
                      )}

                    {userPaymentMethod.name.toString() === 'withdrawal' && (
                      <p>
                        O seu pedido irá para preparo. Acompanhe o status na
                        opção
                        <strong> Meus Pedidos</strong>
. Você receberá um sms
                        assim que seu pedido ficar pronto para retirada. Efetue
                        o pagamento ao retirar o pedido.
</p>
                    )}

                    {userPaymentMethod.type.toString() === 'delivery' && (
                      <p>
                        O seu pedido foi enviado ao restaurante. Você receberá
                        um sms assim que seu pedido sair para entrega. Acompanhe
                        o status na opção
                        <strong> Meus Pedidos</strong>
. Efetue o pagamento ao
                        receber o pedido.
</p>
                    )}
                  </>
                );
              }
              return (
                <>
                  {userPaymentMethod.name.toString() === 'picpay' &&
                    (userDeliveryType.toString() === 'withdrawal' ||
                      !userDeliveryType) && (
                      <p>
                        Assim que o pagamento for realizado e confirmado, o seu
                        pedido irá para preparo. Caso ainda não o tenha
                        efetuado, basta clicar no botão PicPay abaixo. Acompanhe
                        o status na opção <strong> Meus Pedidos</strong>
{' '}
e fique
                        atento a senha do seu pedido no painel eletrônico da
                        loja.
</p>
                    )}

                  {userPaymentMethod.name.toString() === 'picpay' &&
                    userDeliveryType.toString() === 'delivery' && (
                      <p>
                        Assim que o pagamento for realizado e confirmado, o seu
                        pedido irá para preparo. Caso ainda não o tenha
                        efetuado, basta clicar no botão PicPay abaixo. Acompanhe
                        o status na opção
{' '}
<strong> Meus Pedidos</strong>{' '}
                      </p>
                    )}

                  {userPaymentMethod.type.toString() === 'online' &&
                    userPaymentMethod.name.toString() === 'credito' &&
                    (userDeliveryType.toString() === 'withdrawal' ||
                      !userDeliveryType) && (
                      <p>
                        Assim que o pagamento for realizado e confirmado, o seu
                        pedido irá para preparo. Acompanhe o status na opção
                        <strong> Meus Pedidos</strong>
{' '}
e fique atento a senha do
                        seu pedido no painel eletrônico da loja.
</p>
                    )}

                  {userPaymentMethod.type.toString() === 'online' &&
                    userPaymentMethod.name.toString() === 'credito' &&
                    userDeliveryType.toString() === 'delivery' && (
                      <p>
                        Assim que o pagamento for realizado e confirmado, o seu
                        pedido irá para preparo. Acompanhe o status na opção
                        <strong> Meus Pedidos</strong>
.
</p>
                    )}

                  {userPaymentMethod.name.toString() === 'withdrawal' && (
                    <p>
                      O seu pedido foi enviado ao restaurante. Acompanhe o
                      status na opção
                      <strong> Meus Pedidos</strong>
{' '}
e fique atento a senha do
                      seu pedido no painel eletrônico da loja. Efetue o
                      pagamento ao retirar o pedido.
</p>
                  )}

                  {userPaymentMethod.type.toString() === 'delivery' && (
                    <p>
                      O seu pedido foi enviado ao restaurante. Acompanhe o
                      status na opção
                      <strong> Meus Pedidos</strong>
. Efetue o pagamento ao
                      receber o pedido.
</p>
                  )}
                </>
              );
            }
            return (
              <>
                {userPaymentMethod.name.toString() === 'picpay' && (
                  <p>
                    Assim que o pagamento for realizado e confirmado, o seu
                    pedido irá para preparo. Caso ainda não o tenha efetuado,
                    basta clicar no botão PicPay abaixo. Acompanhe o status na
                    opção
{' '}
<strong>Meus Pedidos</strong> e fique atento a senha
                    do seu pedido no painel eletrônico da loja.
                  </p>
                )}

                {userPaymentMethod.type.toString() === 'online' &&
                  userPaymentMethod.name.toString() === 'credito' && (
                    <p>
                      Assim que o pagamento for realizado e confirmado, o seu
                      pedido irá para preparo. Acompanhe o status na opção
{' '}
                      <strong>Meus Pedidos</strong> e fique atento a senha do
                      seu pedido no painel eletrônico da loja.
                    </p>
                  )}

                {userPaymentMethod.type.toString() === 'online' &&
                  userPaymentMethod.name.toString() === 'credito' &&
                  userDeliveryType.toString() === 'delivery' && (
                    <p>
                      Assim que o pagamento for realizado e confirmado, o seu
                      pedido irá para preparo. Acompanhe o status na opção
                      <strong> Meus Pedidos</strong>
.
</p>
                  )}

                {userPaymentMethod.name.toString() === 'withdrawal' && (
                  <p>
                    O seu pedido foi enviado ao restaurante. Acompanhe o status
                    na opção
{' '}
<strong>Meus Pedidos</strong>
                    {' '}
                    e fique atento a
                    senha do seu pedido no painel eletrônico da loja. Efetue o
                    pagamento ao retirar o pedido.
</p>
                )}

                {userPaymentMethod.type.toString() === 'delivery' && (
                  <p>
                    O seu pedido irá para preparo. Acompanhe o status na opção
                    <strong> Meus Pedidos</strong>. Efetue o pagamento ao
                    receber o pedido.
                  </p>
                )}
              </>
            );
          })()}

          {userPaymentMethod.name.toString() === 'picpay' && (
            <PaymentLink
              href={`picpay://picpay/checkout/${picpayReferenceId}`}
              target="_blank"
            >
              <img src={logoPicpay} alt="logo PicPay" />
              <p>Ir para o Picpay!</p>
            </PaymentLink>
          )}
        </Message>

        {userPaymentMethod.name.toString() === 'withdrawal' ||
        userPaymentMethod.type.toString() === 'delivery' ? (
          <Button onClick={handleNavigate}>Ok, entendi !</Button>
        ) : (
          <Button onClick={handleNavigate}>Já realizei o pagamento</Button>
        )}
      </Content>
    </Container>
  );
};

export default AlmostThere;
