import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';

import formatTime from '../../utils/formatTime';
import formatValue from '../../utils/formatValue';

import {
  Container,
  Content,
  Header,
  OrderDetails,
  OrderDetailsHeader,
  OrderDetailsBody,
  OrderDetailsFooter,
  OrderStatus,
  OrderTime,
  OrderKey,
  Orders,
  OrderProduct,
  OrderPrice,
  OrderDiscount,
  OrderDiscountArea,
  ButtonOrders,
} from './styles';

const MyOrdersDetails = () => {
  const location = useLocation();
  const { order: product } = location.state;
  const history = useHistory();

  function handleNavigate() {
    history.goBack();
  }

  return (
    <Container>
      {console.log(product)}
      <Content>
        <Header>
          <h1>Detalhes do Pedido</h1>
        </Header>

        <OrderDetails>
          <OrderDetailsHeader>
            <p>{product.restaurant.fantasy_name}</p>
          </OrderDetailsHeader>
          <OrderDetailsBody>
            <OrderKey>
              {`Senha: ${product.attendance_password.password_number}`}
            </OrderKey>
            <OrderTime>
              {`Pedido feito em ${formatTime(product.start_time)}`}
            </OrderTime>

            <Orders>
              {product.orders.map(item => (
                <React.Fragment key={item.id}>
                  <OrderProduct>
                    <p>{`${item.amount}x ${item.product.name}`}</p>
                    <p>{formatValue(Number(item.total_price))}</p>
                  </OrderProduct>
                </React.Fragment>
              ))}
            </Orders>

            {product.discount > 0 && (
              <OrderDiscountArea>
                <p>Descontos</p>
                <OrderDiscount>{formatValue(product.discount)}</OrderDiscount>
              </OrderDiscountArea>
            )}

            {product.will_receive_sms ? (
              <OrderPrice>
                <p>Total + sms</p>
                <p>{formatValue(Number(product.total_price_with_sms))}</p>
              </OrderPrice>
            ) : (
              <OrderPrice>
                <p>Total</p>
                <p>{formatValue(Number(product.total_price))}</p>
              </OrderPrice>
            )}
          </OrderDetailsBody>
          <OrderDetailsFooter>
            <OrderStatus status={product.order_status}>
              {product.order_status === 'canceled' && <p>Pedido cancelado</p>}
              {product.order_status === 'pending' && (
                <p>Aguardando restaurante</p>
              )}
              {product.order_status === 'accepted' && (
                <p>Preparando o pedido</p>
              )}
              {product.order_status === 'done' &&
                product.delivery_type !== 'delivery' && (
                  <p>Pronto para retirada</p>
                )}
              {product.order_status === 'done' &&
                product.delivery_type === 'delivery' && (
                  <p>Pedido saiu pra entrega</p>
                )}
              {product.order_status === 'finished' && <p>Pedido entregue</p>}
              {product.order_status === 'payment_pending' && (
                <p>Pagamento pendente</p>
              )}
              {product.order_status === 'chargeback' && (
                <p>Pedido cancelado - Pagamento estornado</p>
              )}
            </OrderStatus>
          </OrderDetailsFooter>
        </OrderDetails>

        <ButtonOrders onClick={handleNavigate}>
          <FiArrowLeft />
          Voltar para pedidos
        </ButtonOrders>
      </Content>
    </Container>
  );
};

export default MyOrdersDetails;
