import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Login from '../pages/Login';

import Menu from '../pages/Menu';
import Cart from '../pages/Cart';

import Product from '../pages/Product';
import AlmostThere from '../pages/AlmostThere';
import RestaurantRating from '../pages/RestaurantRating';
import ShoppingRating from '../pages/ShoppingRating';

import Goodbye from '../pages/Goodbye';
import SearchAddress from '../pages/SearchAddress';
import SearchAddressByDistance from '../pages/SearchAddressByDistance';
import Places from '../pages/Places';
import Payments from '../pages/Payments';
import ConfirmOrder from '../pages/ConfirmOrder';
import Orders from '../pages/Orders';
import Coupons from '../pages/Coupons';
import MyOrdersDetails from '../pages/MyOrdersDetails';
import InputCard from '../pages/InputCard';
import RestaurantWelcome from '../pages/RestaurantWelcome';
import DeliveryType from '../pages/DeliveryType';

import { CartProvider } from '../context/cart';
import { OrderProvider } from '../context/order';
import { AuthProvider } from '../context/auth';

const Routes = () => {
  return (
    <Switch>
      <AuthProvider>
        <OrderProvider>
          <CartProvider>
            <Route path="/" exact component={Login} />

            <Route
              path="/qrexpress/:shopping_name"
              isPrivate
              exact
              component={Places}
            />

            <Route
              path="/:is_podi/menu/:user_token/:restaurant_id"
              isPrivate
              component={Menu}
            />

            <Route path="/cart" isPrivate component={Cart} />
            <Route
              path="/qrexpress/foodcourt/:shopping_name/restaurant/:restaurant_name"
              exact
              component={RestaurantWelcome}
            />

            <Route path="/product" isPrivate component={Product} />
            <Route path="/almostthere" isPrivate component={AlmostThere} />
            <Route path="/confirmorder" isPrivate component={ConfirmOrder} />
            <Route path="/rating" isPrivate component={RestaurantRating} />
            <Route
              path="/shoppingrating"
              isPrivate
              component={ShoppingRating}
            />

            <Route path="/payments" isPrivate component={Payments} />

            <Route path="/deliverytype" isPrivate component={DeliveryType} />
            <Route path="/searchaddress" isPrivate component={SearchAddress} />
            <Route
              path="/searchaddressbydistance"
              isPrivate
              component={SearchAddressByDistance}
            />
            <Route path="/goodbye" isPrivate component={Goodbye} />
            <Route path="/orders" isPrivate component={Orders} />
            <Route path="/coupons" isPrivate component={Coupons} />
            <Route path="/inputcard" isPrivate component={InputCard} />
            <Route
              path="/myordersdetails"
              isPrivate
              component={MyOrdersDetails}
            />
          </CartProvider>
        </OrderProvider>
      </AuthProvider>
    </Switch>
  );
};

export default Routes;
