import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  margin: 0 auto;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderAction = styled.div`
  position: fixed;
  top: 0;
  padding-top: 14px;
  padding-right: 10px;
  background: #fff;
  width: 100%;
  max-width: 410px;
  z-index: 10;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;

    h1 {
      font-size: 24px;
      font-weight: bold;
    }
    > div {
      font-size: 14px;
    }
  }
  nav {
    margin-top: 20px;
    height: 50px;
    display: inline-flex;
    width: 100%;
    max-width: 410px;
    .scroll-container {
      display: flex;
      align-content: center;
      padding-bottom: 15px;
      padding-left: 8px;
      li {
        list-style: none;
        display: flex;
        align-items: center;
        .styled-links {
          cursor: pointer;
          color: #333;
          font-size: 14px;
          font-weight: bold;
          text-decoration: none;
          display: flex;
          align-items: center;
          padding: 8px;
          white-space: nowrap;
          min-width: 120px;
          height: 30px;
          margin-right: 8px;
          border: 1px solid rgba(251, 74, 32, 1);
          color: rgba(251, 74, 32, 4);
          justify-content: center;
          border-radius: 25px;
          &:hover {
            background: linear-gradient(
              144deg,
              rgba(251, 74, 32, 1) 28%,
              rgba(250, 5, 97, 1) 100%
            );
            color: #fff;
          }
        }
      }
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 414px;

  display: flex;
  flex-direction: column;
  padding-bottom: 85px;
  margin-top: 75px;
`;

export const Footer = styled.footer`
  width: 100%;
  max-width: 410px;
  height: 65px;
  background: #fff;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #33333310;
  > div {
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    span {
      font-size: 12px;
      color: #333;
      margin-top: -20px;
    }
    img {
      width: 26px;
    }
  }
`;

export const ButtonGoToAccount = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  text-decoration: none;
  color: #333;
  svg {
    margin-bottom: 4px;
  }
`;

export const MenuContent = styled.div`
  background: #fff;
  border-radius: 7px;
  margin-top: 35px;
`;

export const MenuItem = styled.li`
  list-style: none;
  padding: 5px;
`;

export const ProductPromotion = styled.div`
  border-radius: 7px;
  margin-top: 48px;
  padding: 0 8px 10px 8px;
  background: linear-gradient(
    144deg,
    #956d13 15%,
    #ffda7d 30%,
    #e6be69 45%,
    #ffec94 60%,
    #d3a84c 75%,
    #a47a1e 100%
  );
`;

export const Badge = styled.div`
  font-size: 10px;
  background: linear-gradient(
    144deg,
    rgba(251, 74, 32, 1) 28%,
    rgba(250, 5, 97, 1) 100%
  );

  max-width: 100px;
  padding: 8px;
  min-height: 25px;
  color: #fff;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  text-align: center;
  font-weight: bold;
`;

export const AddToCart = styled(Link)`
  width: 100%;
  background: transparent;
  padding-right: 4px;
  text-decoration: none;
  list-style: none;
  border: none;
  display: flex;
  justify-content: space-between;
  > div {
    display: flex;

    img {
      width: 52px;
      height: 52px;
      border-radius: 7px;
      margin-right: 5px;
      ${props =>
        props.disable === 'true' &&
        css`
          filter: opacity(40%);
        `}
    }
  }
`;

export const ButtonGoToCart = styled.div`
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    144deg,
    rgba(251, 74, 32, 1) 28%,
    rgba(250, 5, 97, 1) 100%
  );
  border-radius: 50%;
  margin-bottom: 25px;
  position: relative;
  cursor: pointer;
`;

export const CountBadge = styled.div`
  position: absolute;
  top: 0;
  left: 45px;
  font-size: 12px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  background: #fff;
  width: 22px;
  height: 22px;
  color: rgba(251, 74, 32, 1);
  border: 1px solid rgba(251, 74, 32, 1);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MenuItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f8f8f8;
  border-radius: 7px;
  padding: 5px;
  div {
    font-size: 12px;
    display: flex;
    align-items: center;
    span {
      margin-left: 5px;
      display: flex;
      align-items: center;
      > svg {
        margin-right: 5px;
      }
    }
    svg {
      margin-left: 5px;
    }
  }
  strong {
    font-weight: 700;
  }
`;

export const MenuItemBody = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 20px;
  & + div {
    margin-top: 40px;
  }
`;

export const MenuItemDescription = styled.div`
  width: 190px;
  margin-left: 8px;
  color: #333;
  strong {
    font-weight: 900;
  }
  p {
    font-size: 14px;
    margin-top: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${props =>
    props.disable === 'true' &&
    css`
      filter: opacity(30%);
    `}
`;

export const MenuItemPrice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: #333;
  p {
    font-size: 14px;
    font-weight: 900;
    font-family: Arial, Helvetica, sans-serif;
  }
`;

export const ButtonAction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 25px;
  position: absolute;
`;

export const MenuItemPromotion = styled.p`
  color: red;
  text-decoration: line-through;

  filter: opacity(60%);
`;

export const SoldOff = styled.div`
  color: red;
  font-size: 14px;
  font-weight: bold;

  margin-top: 8px;
`;

export const ButtonWhatsapp = styled.button`
  position: absolute;
  right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border: none;
  background: transparent;
  svg {
    margin-bottom: 4px;
  }
`;

export const ButtonFoodcourt = styled.button`
  position: absolute;
  left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border: none;
  background: transparent;
  svg {
    margin-bottom: 4px;
  }
`;

export const LoadingMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 410px;
  height: 100vh;

  h1 {
    font-size: 18px;
    text-align: center;
    color: #ec0048;
  }

  img {
    width: 100px;
    height: 150px;
  }
`;

export const ProductsEmpty = styled.div`
  width: 100%;
  max-width: 410px;
  height: 50%;

  margin-top: 50%;

  h1 {
    font-size: 18px;
    text-align: center;
  }
`;

export const RestaurantStatus = styled.div`
  width: 100%;
  max-width: 410px;
  height: 50px;
  background: #ff1814;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 100px;
  border-radius: 0 0 7px 7px;

  box-shadow: 0 14px 15px #33333320;
  z-index: 10000;

  p {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
  }
`;

export const ModalTitle = styled.div`
  p {
    text-align: left;
    font-weight: bold;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  margin-top: 16px;
  padding-top: 8px;
  border-top: 1px solid #33333310;
  display: flex;
  justify-content: flex-end;
`;

export const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #4ceb5b;
  border: none;
  border-radius: 7px;
  margin-right: 8px;
`;
