import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { FaUtensils } from 'react-icons/fa';

import api from '../../services/api';

import { useCart } from '../../context/cart';
import { useAuth } from '../../context/auth';

import {
  Container,
  Content,
  Footer,
  Title,
  RestaurantMinimumPrice,
} from './styles';

const RestaurantWelcome = () => {
  const history = useHistory();
  const { shopping_name, restaurant_name } = useParams();
  const [restaurantIdState, setRestaurantIdState] = useState();
  const [restaurantAvatar, setRestaurantAvatar] = useState('');
  const [restaurantName, setRestaurantName] = useState('');
  const [deliveryPrice, setDeliveryPrice] = useState('');
  const { closeCart } = useCart();
  const {
    setRestaurantId,
    setRestaurantFantasyName,
    setShopping,
    setSmsOptions,
    setRestaurantWhatsapp,
    setRestaurantAcceptsPicpay,
    setRestaurantAcceptsPaytime,
    setRestaurantIsOpen,
    setRestaurantAcceptsWithdrawal,
    setRestaurantAcceptsDelivery,
    setRestaurantDeliveryActive,
    setRestaurantDeliveryPayments,
    setRestaurantDeliveryTime,
    setRestaurantWithdrawalTime,
    setRestaurantDeliveryMinimumPrice,
    setRestaurantCouponCode,
    setPicpayReferenceId,
    setIsDeliveryByDistance,
    setRestaurantAddress,
    userLogout,
  } = useAuth();

  const verifyToken = async () => {
    try {
      await api.get('/client/verify/session');
      console.log('Token Ok');
    } catch (err) {
      if (!err.response.ok) {
        switch (err.response.data.errorType) {
          case 'invalid_token':
            console.log(err.message);
            userLogout();
        }
      }
    }
  };

  useEffect(() => {
    verifyToken();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const resp = await api.get(`/public/restaurant/${restaurant_name}`);

      console.log(resp.data);

      const {
        id,
        client_pay_sms,
        has_paytime_service,
        has_picpay_service,
        has_sms_service,
        withdrawal_allowed,
        is_sms_service_optional,
        fantasy_name,
        opened,
        whatsapp,
        avatar,
        is_delivery_allowed,
        is_delivery_active,
        payment_methods,
        time_to_delivery,
        time_to_withdrawal,
        minimum_delivery_price,
        is_delivery_by_distance,
        address,
      } = resp.data;

      setRestaurantIdState(id);
      setRestaurantName(fantasy_name);

      if (whatsapp) {
        const restaurantWhatsapp = whatsapp
          .replace('(', '')
          .replace(')', '')
          .replace(' ', '')
          .replace(' ', '')
          .replace('+', '')
          .replace('-', '');

        setRestaurantWhatsapp(restaurantWhatsapp);
      } else {
        setRestaurantWhatsapp('');
      }

      if (avatar) {
        setRestaurantAvatar(avatar.url);
      }

      setRestaurantId(id);
      setRestaurantFantasyName(fantasy_name);

      setSmsOptions(client_pay_sms, is_sms_service_optional, has_sms_service);
      setRestaurantAcceptsPicpay(has_picpay_service);
      setRestaurantAcceptsPaytime(has_paytime_service);
      setRestaurantIsOpen(opened);
      setRestaurantAcceptsWithdrawal(withdrawal_allowed);
      setRestaurantAcceptsDelivery(is_delivery_allowed || false);
      setRestaurantDeliveryActive(is_delivery_active || false);
      setRestaurantDeliveryPayments(payment_methods || null);
      setRestaurantDeliveryTime(time_to_delivery || null);
      setRestaurantWithdrawalTime(time_to_withdrawal || null);
      setRestaurantDeliveryMinimumPrice(minimum_delivery_price || null);

      setDeliveryPrice(minimum_delivery_price);
      setIsDeliveryByDistance(is_delivery_by_distance || '');

      setRestaurantAddress(address ? address.state : null);

      closeCart();
    }

    fetchData();
  }, [
    restaurant_name,
    setRestaurantAcceptsPaytime,
    setRestaurantAcceptsPicpay,
    setRestaurantFantasyName,
    setRestaurantId,
    setRestaurantIsOpen,
    setRestaurantWhatsapp,
    setSmsOptions,
    setRestaurantAcceptsWithdrawal,
    setRestaurantAcceptsDelivery,
    setRestaurantDeliveryPayments,
    setRestaurantDeliveryTime,
    setRestaurantDeliveryActive,
    setRestaurantWithdrawalTime,
    setRestaurantDeliveryMinimumPrice,
    setDeliveryPrice,
    setIsDeliveryByDistance,
    closeCart,
    setRestaurantAddress,
  ]);

  useEffect(() => {
    setRestaurantCouponCode('');
    setPicpayReferenceId('');
  }, [setRestaurantCouponCode, setPicpayReferenceId]);

  useEffect(() => {
    async function fetchShoppingData() {
      const response = await api.get(`/public/qrexpress/${shopping_name}`);
      const { id, username } = response.data;

      setShopping(id, username);
    }
    fetchShoppingData();
  }, [setShopping, shopping_name]);

  const handleNavigate = useCallback(() => {
    history.push(`/takeat/menu/restaurant/${restaurantIdState}`);
  }, [history, restaurantIdState]);

  return (
    <Container>
      <Content>
        <Title>
          {!!restaurantAvatar && (
            <img src={restaurantAvatar} alt="Logotipo do restaurante" />
          )}
          <h1>
            Seja bem-vindo(a)
            <br /> <br />
            <span>
{' '}
{restaurantName}!</span>
          </h1>

          {deliveryPrice > 0 && (
            <RestaurantMinimumPrice>{`Pedido minimo no delivery: R$ ${deliveryPrice}`}</RestaurantMinimumPrice>
          )}
        </Title>

        <Footer>
          <button onClick={handleNavigate} type="button">
            {' '}
            <FaUtensils />
            Acessar cardápio
          </button>
        </Footer>
      </Content>
    </Container>
  );
};

export default RestaurantWelcome;
