import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 410px;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Footer = styled.footer`
  width: 100%;
  max-width: 410px;
  position: fixed;
  bottom: 0;

  button {
    width: 100%;
    max-width: 414px;
    height: 48px;
    background: #0aa5ff;
    color: #fff;
    border-radius: 7px 7px 0 0;
    border: none;

    svg {
      margin-right: 8px;
    }
  }
`;

export const Title = styled.div`
  width: 100%;
  max-width: 410px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 20px;
    text-align: center;

    span {
      font-weight: bold;
    }
  }

  img {
    width: 200px;
  }
`;

export const RestaurantMinimumPrice = styled.div`
  border: 1px solid #0aa5ff;
  border-radius: 7px;
  padding: 10px 15px;
  margin-top: 16px;
  color: #0aa5ff;
`;
