import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import { uuid } from 'uuidv4';

import api from '../../services/api';

import OrderError from '../../pages/OrderError';
import { useAuth } from '../auth';
import { ModalFooter, ModalTitle, ConfirmButton } from './styles';

const OrderContext = createContext();

const OrderProvider = ({ children }) => {
  const history = useHistory();
  const [errorComponent, setErrorComponent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorAction, setErrorAction] = useState([]);
  const [modal, setModal] = useState(false);
  Modal.setAppElement('#root');

  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '7px',
    },
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const {
    setBasketId,
    restaurantId,
    userChosePaySms,
    setPicpayUrlPayment,
    setPicpayReferenceId,
    restaurantCouponCode,
    setRestaurantCouponCode,
    shoppingName,
    userLogout,
    userDeliveryType,
    userAddressId,
    userPaymentMethod,
    userChange,
    userToken,
    isUserPodi,
  } = useAuth();

  const [orders, setOrders] = useState(() => {
    const orderStorage = localStorage.getItem('@qrexpress:order');

    if (orderStorage) {
      return JSON.parse(orderStorage);
    }

    return [];
  });

  const [ordersTotalValue, setOrdersTotalValue] = useState(() => {
    const ordersTotalValueStorage = localStorage.getItem(
      '@qrexpress:ordersTotalValue',
    );

    if (ordersTotalValueStorage) {
      return ordersTotalValueStorage;
    }

    return '';
  });

  useEffect(() => {
    localStorage.setItem('@qrexpress:order', JSON.stringify(orders));
  }, [orders]);

  useEffect(() => {
    localStorage.setItem('@qrexpress:ordersTotalValue', ordersTotalValue);
  }, [ordersTotalValue]);

  const addToOrder = useCallback(
    async (cart, payment_method, paytime_token) => {
      setOrders([...orders, { order: [...cart], id: uuid() }]);

      const lastOrder = [...cart];

      switch (payment_method) {
        case 'picpay':
          try {
            const res = await api.post('client/orders', {
              order: lastOrder,
              restaurant_id: restaurantId,
              will_receive_sms: userChosePaySms,
              payment_method: 'picpay',
              coupon_code: restaurantCouponCode || null,
              delivery_type: userDeliveryType || 'withdrawal',
              buyer_address_id: userAddressId || 0,
              user_change: userChange || 0,
            });

            const { orderBasket, payment } = res.data;

            setBasketId(orderBasket.order_uuid);

            setPicpayUrlPayment(payment.paymentUrl);

            const picpayId = payment.paymentUrl.substring(32);

            setPicpayReferenceId(picpayId);

            window.open(payment.paymentUrl, '_blank');

            setRestaurantCouponCode(null);

            history.push('/almostthere');
          } catch (err) {
            if (!err.response.ok) {
              switch (err.response.data.errorType) {
                case 'invalid_token':
                  toggleModal();
                  userLogout();
                  history.push(`/cart`);
                  break;

                case 'token_not_sent':
                  toggleModal();
                  userLogout();
                  history.push(`/cart`);
                  break;
                case 'minimum_delivery_price':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Este pedido está abaixo do preço mínimo pro delivery, vamos te redirecionar para o cardápio.',
                  );
                  if (isUserPodi === 'takeat') {
                    setErrorAction([
                      () => {
                        window.location.href = `takeat/menu/restaurant/${restaurantId}`;
                      },
                    ]);
                  }
                  if (isUserPodi === 'podi') {
                    setErrorAction([
                      () => {
                        window.location.href = `podi/menu/${userToken}/${restaurantId}`;
                      },
                    ]);
                  }

                  setRestaurantCouponCode('');
                  break;
                case 'coupon_wrong_restaurant':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Este cupom não pertence a este restaurante, favor refaça a confirmação de pagamento escolhendo outro cupom disponível, caso possua..',
                  );
                  setErrorAction([
                    () => {
                      history.push('/confirmorder');
                    },
                  ]);
                  setRestaurantCouponCode('');
                  break;

                case 'client_coupon_buy_limit':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Você atingiu o limite de uso individual desse cupom. Favor refaça a confirmação de pagamento escolhendo outro cupom disponível, caso possua.',
                  );
                  setErrorAction([
                    () => {
                      window.location.href = '/confirmorder';
                    },
                  ]);
                  setRestaurantCouponCode('');
                  break;

                case 'coupon_amount_ended':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Este cupom atingiu seu limite de uso, favor refaça a confirmação de pagamento escolhendo outro cupom disponível, caso possua.',
                  );
                  setErrorAction([
                    () => {
                      window.location.href = '/confirmorder';
                    },
                  ]);
                  setRestaurantCouponCode('');
                  break;

                case 'coupon_not_active':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Este cupom não está mais ativo, favor refaça a confirmação de pagamento escolhendo outro cupom disponível, caso possua.',
                  );
                  setErrorAction([
                    () => {
                      window.location.href = '/confirmorder';
                    },
                  ]);
                  setRestaurantCouponCode('');
                  break;

                case 'coupon_not_found':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Cupom não encontrado, favor refaça a confirmação de pagamento escolhendo outro cupom disponível, caso possua.',
                  );
                  setErrorAction([
                    () => {
                      window.location.href = '/confirmorder';
                    },
                  ]);
                  setRestaurantCouponCode('');
                  break;

                case 'coupon_date_expired':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Este cupom expirou, favor refaça a confirmação de pagamento escolhendo outro cupom disponível, caso possua.',
                  );
                  setErrorAction([
                    () => {
                      window.location.href = '/confirmorder';
                    },
                  ]);
                  setRestaurantCouponCode('');
                  break;

                case 'coupon_minimum_price':
                  setErrorComponent(true);
                  setErrorMessage(
                    'O valor da compra está abaixo do valor mínimo para uso do cupom, favor utilize um cupom válido para esta compra.',
                  );
                  setErrorAction([
                    () => {
                      window.location.href = '/confirmorder';
                    },
                  ]);
                  setRestaurantCouponCode('');
                  break;

                case 'restaurant_not_found':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Restaurante não encontrado. Vamos te redirecionar para praça de alimentação.',
                  );
                  setErrorAction([
                    () => {
                      history.push(`/qrexpress/${shoppingName}`);
                    },
                  ]);

                  break;

                case 'restaurant_closed':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Esse Restaurante está fechado. Vamos te redirecionar para praça de alimentação.',
                  );
                  setErrorAction([
                    () => {
                      history.push(`/qrexpress/${shoppingName}`);
                    },
                  ]);
                  break;

                case 'food_court_not_found':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Esse Restaurante não possui praça de alimentação. Vamos te redirecionar para a praça de alimentação correta.',
                  );
                  setErrorAction([
                    () => {
                      history.push(`/qrexpress/${shoppingName}`);
                    },
                  ]);
                  break;

                case 'payment_failed':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Erro ao efetuar o pagamento. Tente novamente, ou mude a forma de pagamento.',
                  );
                  setErrorAction([
                    () => {
                      history.push(`/payments`);
                    },
                  ]);
                  break;

                case 'picpay_payment_failed':
                  setErrorComponent(true);
                  setErrorMessage(
                    'PicPay está com problemas no momento. Tente novamente, ou mude a forma de pagamento.',
                  );
                  setErrorAction([
                    () => {
                      history.push(`/payments`);
                    },
                  ]);
                  break;

                default:
                  setErrorComponent(true);
                  setErrorMessage(
                    'O sistema de pagamento está apresentando falha. Tente novamente mais tarde.',
                  );
                  setErrorAction([
                    () => {
                      history.push(`/payments`);
                    },
                  ]);
              }
            }
          }
          break;
        case 'credito':
          try {
            const res = await api.post('client/orders', {
              order: lastOrder,
              restaurant_id: restaurantId,
              will_receive_sms: userChosePaySms,
              payment_method: 'paytime',
              payment_token: paytime_token,
              coupon_code: restaurantCouponCode || null,
              delivery_type: userDeliveryType || 'withdrawal',
              buyer_address_id: userAddressId || 0,
              user_change: userChange || 0,
            });

            const { orderBasket } = res.data;

            setBasketId(orderBasket.order_uuid);

            setRestaurantCouponCode(null);

            history.push('/almostthere');
          } catch (err) {
            if (!err.response.ok) {
              switch (err.response.data.errorType) {
                case 'invalid_token':
                  toggleModal();
                  userLogout();
                  history.push(`/cart`);
                  break;

                case 'token_not_sent':
                  toggleModal();
                  userLogout();
                  history.push(`/cart`);
                  break;
                case 'minimum_delivery_price':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Este pedido está abaixo do preço mínimo pro delivery, vamos te redirecionar para o cardápio.',
                  );
                  if (isUserPodi === 'takeat') {
                    setErrorAction([
                      () => {
                        window.location.href = `takeat/menu/restaurant/${restaurantId}`;
                      },
                    ]);
                  }
                  if (isUserPodi === 'podi') {
                    setErrorAction([
                      () => {
                        window.location.href = `podi/menu/${userToken}/${restaurantId}`;
                      },
                    ]);
                  }

                  setRestaurantCouponCode('');
                  break;
                case 'coupon_wrong_restaurant':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Este cupom não pertence a este restaurante, favor refaça a confirmação de pagamento escolhendo outro cupom disponível, caso possua..',
                  );
                  setErrorAction([
                    () => {
                      window.location.href = '/confirmorder';
                    },
                  ]);
                  setRestaurantCouponCode('');
                  break;

                case 'client_coupon_buy_limit':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Você atingiu o limite de uso individual desse cupom. Favor refaça a confirmação de pagamento escolhendo outro cupom disponível, caso possua.',
                  );
                  setErrorAction([
                    () => {
                      window.location.href = '/confirmorder';
                    },
                  ]);
                  setRestaurantCouponCode('');
                  break;

                case 'coupon_amount_ended':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Este cupom atingiu seu limite de uso, favor refaça a confirmação de pagamento escolhendo outro cupom disponível, caso possua.',
                  );
                  setErrorAction([
                    () => {
                      window.location.href = '/confirmorder';
                    },
                  ]);
                  setRestaurantCouponCode('');
                  break;

                case 'coupon_not_active':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Este cupom não está mais ativo, favor refaça a confirmação de pagamento escolhendo outro cupom disponível, caso possua.',
                  );
                  setErrorAction([
                    () => {
                      window.location.href = '/confirmorder';
                    },
                  ]);
                  setRestaurantCouponCode('');
                  break;

                case 'coupon_not_found':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Cupom não encontrado, favor refaça a confirmação de pagamento escolhendo outro cupom disponível, caso possua.',
                  );
                  setErrorAction([
                    () => {
                      window.location.href = '/confirmorder';
                    },
                  ]);
                  setRestaurantCouponCode('');
                  break;

                case 'coupon_date_expired':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Este cupom expirou, favor refaça a confirmação de pagamento escolhendo outro cupom disponível, caso possua.',
                  );
                  setErrorAction([
                    () => {
                      window.location.href = '/confirmorder';
                    },
                  ]);
                  setRestaurantCouponCode('');
                  break;

                case 'coupon_minimum_price':
                  setErrorComponent(true);
                  setErrorMessage(
                    'O valor da compra está abaixo do valor mínimo para uso do cupom, favor utilize um cupom válido para esta compra.',
                  );
                  setErrorAction([
                    () => {
                      window.location.href = '/confirmorder';
                    },
                  ]);
                  setRestaurantCouponCode('');
                  break;

                case 'restaurant_not_found':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Restaurante não encontrado. Vamos te redirecionar para praça de alimentação.',
                  );
                  setErrorAction([
                    () => {
                      history.push(`/qrexpress/${shoppingName}`);
                    },
                  ]);
                  break;

                case 'restaurant_closed':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Esse Restaurante está fechado. Vamos te redirecionar para praça de alimentação.',
                  );
                  setErrorAction([
                    () => {
                      history.push(`/qrexpress/${shoppingName}`);
                    },
                  ]);
                  break;

                case 'food_court_not_found':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Esse Restaurante não possui praça de alimentação. Vamos te redirecionar para a praça de alimentação correta.',
                  );
                  setErrorAction([
                    () => {
                      history.push(`/qrexpress/${shoppingName}`);
                    },
                  ]);
                  break;

                case 'payment_failed':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Erro ao efetuar o pagamento. Tente novamente, ou mude a forma de pagamento.',
                  );
                  setErrorAction([
                    () => {
                      history.push(`/payments`);
                    },
                  ]);
                  break;

                case 'paytime_offline':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Esse método de pagamento apresentou erro. Tente novamente, ou mude a forma de pagamento.',
                  );
                  setErrorAction([
                    () => {
                      history.push(`/payments`);
                    },
                  ]);
                  break;

                case 'paytime_not_authorized':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Esse método de pagamento apresentou erro. Tente novamente, ou mude a forma de pagamento.',
                  );
                  setErrorAction([
                    () => {
                      history.push(`/payments`);
                    },
                  ]);
                  break;

                default:
                  setErrorComponent(true);
                  setErrorMessage(
                    'O sistema de pagamento está apresentando falha. Tente novamente mais tarde.',
                  );
                  setErrorAction([
                    () => {
                      history.push(`/payments`);
                    },
                  ]);
              }
            }
          }
          break;
        case 'withdrawal':
          try {
            const res = await api.post('client/orders', {
              order: lastOrder,
              restaurant_id: restaurantId,
              will_receive_sms: userChosePaySms,
              payment_method: 'withdrawal',
              coupon_code: restaurantCouponCode || null,
              delivery_type: 'withdrawal',
              buyer_address_id: userAddressId || 0,
              user_change: userChange || 0,
            });

            const { orderBasket } = res.data;

            setBasketId(orderBasket.order_uuid);

            setRestaurantCouponCode(null);

            history.push('/almostthere');
          } catch (err) {
            if (!err.response.ok) {
              switch (err.response.data.errorType) {
                case 'invalid_token':
                  toggleModal();
                  userLogout();
                  history.push(`/cart`);
                  break;

                case 'token_not_sent':
                  toggleModal();
                  userLogout();
                  history.push(`/cart`);
                  break;
                case 'coupon_wrong_restaurant':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Este cupom não pertence a este restaurante, favor refaça a confirmação de pagamento escolhendo outro cupom disponível, caso possua..',
                  );
                  setErrorAction([
                    () => {
                      window.location.href = '/confirmorder';
                    },
                  ]);
                  setRestaurantCouponCode('');
                  break;

                case 'client_coupon_buy_limit':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Você atingiu o limite de uso individual desse cupom. Favor refaça a confirmação de pagamento escolhendo outro cupom disponível, caso possua.',
                  );
                  setErrorAction([
                    () => {
                      window.location.href = '/confirmorder';
                    },
                  ]);
                  setRestaurantCouponCode('');
                  break;

                case 'coupon_amount_ended':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Este cupom atingiu seu limite de uso, favor refaça a confirmação de pagamento escolhendo outro cupom disponível, caso possua.',
                  );
                  setErrorAction([
                    () => {
                      window.location.href = '/confirmorder';
                    },
                  ]);
                  setRestaurantCouponCode('');
                  break;

                case 'coupon_not_active':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Este cupom não está mais ativo, favor refaça a confirmação de pagamento escolhendo outro cupom disponível, caso possua.',
                  );
                  setErrorAction([
                    () => {
                      window.location.href = '/confirmorder';
                    },
                  ]);
                  setRestaurantCouponCode('');
                  break;

                case 'coupon_not_found':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Cupom não encontrado, favor refaça a confirmação de pagamento escolhendo outro cupom disponível, caso possua.',
                  );
                  setErrorAction([
                    () => {
                      window.location.href = '/confirmorder';
                    },
                  ]);
                  setRestaurantCouponCode('');
                  break;

                case 'coupon_date_expired':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Este cupom expirou, favor refaça a confirmação de pagamento escolhendo outro cupom disponível, caso possua.',
                  );
                  setErrorAction([
                    () => {
                      window.location.href = '/confirmorder';
                    },
                  ]);
                  setRestaurantCouponCode('');
                  break;

                case 'coupon_minimum_price':
                  setErrorComponent(true);
                  setErrorMessage(
                    'O valor da compra está abaixo do valor mínimo para uso do cupom, favor utilize um cupom válido para esta compra.',
                  );
                  setErrorAction([
                    () => {
                      window.location.href = '/confirmorder';
                    },
                  ]);
                  setRestaurantCouponCode('');
                  break;

                case 'restaurant_not_found':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Restaurante não encontrado. Vamos te redirecionar para praça de alimentação.',
                  );
                  setErrorAction([
                    () => {
                      history.push(`/qrexpress/${shoppingName}`);
                    },
                  ]);
                  break;

                case 'restaurant_closed':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Esse Restaurante está fechado. Vamos te redirecionar para praça de alimentação.',
                  );
                  setErrorAction([
                    () => {
                      history.push(`/qrexpress/${shoppingName}`);
                    },
                  ]);
                  break;

                case 'food_court_not_found':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Esse Restaurante não possui praça de alimentação. Vamos te redirecionar para a praça de alimentação correta.',
                  );
                  setErrorAction([
                    () => {
                      history.push(`/qrexpress/${shoppingName}`);
                    },
                  ]);
                  break;

                case 'payment_failed':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Erro ao efetuar o pagamento. Tente novamente, ou mude a forma de pagamento.',
                  );
                  setErrorAction([
                    () => {
                      history.push(`/payments`);
                    },
                  ]);
                  break;

                default:
                  setErrorComponent(true);
                  setErrorMessage(
                    'O sistema de pagamento está apresentando falha. Tente novamente mais tarde.',
                  );
                  setErrorAction([
                    () => {
                      history.push(`/payments`);
                    },
                  ]);
              }
            }
          }
          break;

        case 'delivery':
          try {
            const res = await api.post('client/orders', {
              order: lastOrder,
              restaurant_id: restaurantId,
              will_receive_sms: userChosePaySms,
              payment_method: userPaymentMethod.keyword,
              coupon_code: restaurantCouponCode || null,
              delivery_type: 'delivery',
              buyer_address_id: userAddressId || 0,
              user_change: userChange || 0,
            });

            const { orderBasket } = res.data;

            console.log(userPaymentMethod);

            setBasketId(orderBasket.order_uuid);

            setRestaurantCouponCode(null);

            history.push('/almostthere');
          } catch (err) {
            if (!err.response.ok) {
              switch (err.response.data.errorType) {
                case 'invalid_token':
                  toggleModal();
                  userLogout();
                  history.push(`/cart`);
                  break;

                case 'token_not_sent':
                  toggleModal();
                  userLogout();
                  history.push(`/cart`);
                  break;
                case 'minimum_delivery_price':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Este pedido está abaixo do preço mínimo pro delivery, vamos te redirecionar para o cardápio.',
                  );
                  if (isUserPodi === 'takeat') {
                    setErrorAction([
                      () => {
                        window.location.href = `takeat/menu/restaurant/${restaurantId}`;
                      },
                    ]);
                  }
                  if (isUserPodi === 'podi') {
                    setErrorAction([
                      () => {
                        window.location.href = `podi/menu/${userToken}/${restaurantId}`;
                      },
                    ]);
                  }

                  setRestaurantCouponCode('');
                  break;
                case 'coupon_wrong_restaurant':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Este cupom não pertence a este restaurante, favor refaça a confirmação de pagamento escolhendo outro cupom disponível, caso possua..',
                  );
                  setErrorAction([
                    () => {
                      window.location.href = '/confirmorder';
                    },
                  ]);
                  setRestaurantCouponCode('');
                  break;

                case 'client_coupon_buy_limit':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Você atingiu o limite de uso individual desse cupom. Favor refaça a confirmação de pagamento escolhendo outro cupom disponível, caso possua.',
                  );
                  setErrorAction([
                    () => {
                      window.location.href = '/confirmorder';
                    },
                  ]);
                  setRestaurantCouponCode('');
                  break;

                case 'coupon_amount_ended':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Este cupom atingiu seu limite de uso, favor refaça a confirmação de pagamento escolhendo outro cupom disponível, caso possua.',
                  );
                  setErrorAction([
                    () => {
                      window.location.href = '/confirmorder';
                    },
                  ]);
                  setRestaurantCouponCode('');
                  break;

                case 'coupon_not_active':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Este cupom não está mais ativo, favor refaça a confirmação de pagamento escolhendo outro cupom disponível, caso possua.',
                  );
                  setErrorAction([
                    () => {
                      window.location.href = '/confirmorder';
                    },
                  ]);
                  setRestaurantCouponCode('');
                  break;

                case 'coupon_not_found':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Cupom não encontrado, favor refaça a confirmação de pagamento escolhendo outro cupom disponível, caso possua.',
                  );
                  setErrorAction([
                    () => {
                      window.location.href = '/confirmorder';
                    },
                  ]);
                  setRestaurantCouponCode('');
                  break;

                case 'coupon_date_expired':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Este cupom expirou, favor refaça a confirmação de pagamento escolhendo outro cupom disponível, caso possua.',
                  );
                  setErrorAction([
                    () => {
                      window.location.href = '/confirmorder';
                    },
                  ]);
                  setRestaurantCouponCode('');
                  break;

                case 'coupon_minimum_price':
                  setErrorComponent(true);
                  setErrorMessage(
                    'O valor da compra está abaixo do valor mínimo para uso do cupom, favor utilize um cupom válido para esta compra.',
                  );
                  setErrorAction([
                    () => {
                      window.location.href = '/confirmorder';
                    },
                  ]);
                  setRestaurantCouponCode('');
                  break;

                case 'restaurant_not_found':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Restaurante não encontrado. Vamos te redirecionar para praça de alimentação.',
                  );
                  setErrorAction([
                    () => {
                      history.push(`/qrexpress/${shoppingName}`);
                    },
                  ]);
                  break;

                case 'restaurant_closed':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Esse Restaurante está fechado. Vamos te redirecionar para praça de alimentação.',
                  );
                  setErrorAction([
                    () => {
                      history.push(`/qrexpress/${shoppingName}`);
                    },
                  ]);
                  break;

                case 'food_court_not_found':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Esse Restaurante não possui praça de alimentação. Vamos te redirecionar para a praça de alimentação correta.',
                  );
                  setErrorAction([
                    () => {
                      history.push(`/qrexpress/${shoppingName}`);
                    },
                  ]);
                  break;

                case 'payment_failed':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Erro ao efetuar o pagamento. Tente novamente, ou mude a forma de pagamento.',
                  );
                  setErrorAction([
                    () => {
                      history.push(`/payments`);
                    },
                  ]);
                  break;

                default:
                  setErrorComponent(true);
                  setErrorMessage(
                    'O sistema de pagamento está apresentando falha. Tente novamente mais tarde.',
                  );
                  setErrorAction([
                    () => {
                      history.push(`/payments`);
                    },
                  ]);
              }
            }
          }
          break;
      }
    },

    [
      orders,
      history,
      restaurantId,
      setBasketId,
      userChosePaySms,
      setPicpayUrlPayment,
      restaurantCouponCode,
      setRestaurantCouponCode,
      shoppingName,
      userLogout,
      userAddressId,
      userDeliveryType,
      userPaymentMethod,
      userChange,
      setPicpayReferenceId,
      toggleModal,
      isUserPodi,
      userToken,
    ],
  );

  const closeOrder = useCallback(() => {
    localStorage.removeItem('@qrexpress:order');
    localStorage.removeItem('@qrexpress:cart');
    localStorage.removeItem('@qrexpress:basketId');

    setOrders([]);
  }, []);

  const value = useMemo(
    () => ({
      addToOrder,
      orders,
      closeOrder,
      setOrdersTotalValue,
      ordersTotalValue,
      setErrorComponent,
    }),
    [addToOrder, orders, closeOrder, ordersTotalValue, setErrorComponent],
  );

  return (
    <OrderContext.Provider value={value}>
      {children}
      <OrderError
        error={errorComponent}
        errorMessage={errorMessage}
        errorAction={errorAction}
      />
      <Modal
        isOpen={modal}
        onRequestClose={toggleModal}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <p>
            Seu login expirou, favor preencher seus dados novamente, e
            prosseguir com o pedido
          </p>
        </ModalTitle>
        <ModalFooter>
          <ConfirmButton onClick={toggleModal}>Ok, entendi</ConfirmButton>
        </ModalFooter>
      </Modal>
    </OrderContext.Provider>
  );
};

function useOrder() {
  const context = useContext(OrderContext);

  if (!context) {
    console.log('useOrder must be within a provider');
  }

  return context;
}

export { OrderProvider, useOrder };
