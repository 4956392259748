import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { shade } from 'polished';

export const Container = styled.div`
  margin: 0 auto;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderAction = styled.div`
  width: 100%;
  max-width: 410px;
  position: fixed;
  top: 0;
  padding: 10px;

  padding-top: 14px;
  padding-bottom: 14px;
  background: #fff;
  border-bottom: 1px solid #f8f8f8;
  z-index: 100;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 410px;

  display: flex;
  flex-direction: column;
  padding-bottom: 65px;
  margin-top: 65px;
  background: #fff;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      font-size: 24px;
      font-weight: bold;
    }

    a {
      color: #fb4a20;
      font-size: 12px;
      font-weight: 900;
    }
  }
`;

export const MenuContent = styled.div`
  width: 100%;
  max-width: 410px;
  background: #fff;
  border-radius: 7px;
`;

export const MenuItem = styled.li`
  list-style: none;
  padding: 15px;
  width: 100%;
`;

export const ItemListHeader = styled.div`
  color: #333;
  margin-top: 16px;
  padding: 10px;
  border-top: 1px solid #33333310;

  > div {
    display: flex;
    justify-content: space-between;
  }
`;

export const Footer = styled.footer`
  width: 100%;
  max-width: 410px;
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-top: 1px solid #f8f8f8;
  position: fixed;
  bottom: 0;
  padding: 10px;

  z-index: 90;

  div {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      font-size: 20px;
      font-weight: bold;
      font-family: Arial, Helvetica, sans-serif;
    }
  }

  button {
    width: 60%;
    max-width: 414px;
    height: 48px;
    background: linear-gradient(
      144deg,
      rgba(251, 74, 32, 1) 28%,
      rgba(250, 5, 97, 1) 100%
    );
    color: #fff;
    border-radius: 7px;
    border: none;
    font-size: 14px;
  }
`;

export const RouterDomLink = styled.div`
  text-decoration: none;
  color: ${shade(0.1, '#333333')};
  display: flex;
  align-items: center;

  p {
    font-size: 12px;
    font-weight: 600;
    color: rgba(251, 74, 32, 1);
  }

  svg {
    margin-right: 4px;
  }
`;

export const CartDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  padding: 10px;

  strong {
    font-size: 20px;
    font-weight: 700;
  }

  p {
    font-size: 14px;
    margin-bottom: 8px;
  }

  > div {
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 10px;
    display: flex;

    p {
      margin-left: 5px;
    }
  }
`;

export const MenuItemBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + div {
    margin-top: 20px;
  }
`;

export const MenuItemImage = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 52px;
    height: 52px;
    border-radius: 7px;
    margin-right: 15px;
  }
`;

export const MenuItemDescription = styled.div`
  width: 490px;

  > div {
    display: flex;
    align-items: center;
    margin-top: 5px;

    span {
      font-size: 12px;
    }

    svg {
      margin-right: 5px;
    }
  }

  p {
    font-size: 12px;
    margin-top: 5px;
  }
`;

export const MenuItemComplements = styled.div`
  display: flex;
  flex-wrap: wrap;

  p {
    color: #fb4a20;
    font-size: 10px;
    padding: 3px 5px;
    margin-right: 5px;
    border: 1px solid #fb4a20;
    border-radius: 14px;
  }
`;

export const MenuItemQuantity = styled.div`
  display: flex;
  width: 150px;
  justify-content: flex-end;
  align-items: center;

  svg {
    margin-left: 10px;
    cursor: pointer;
  }

  p {
    font-size: 24px;
    font-family: Arial, Helvetica, sans-serif;
    color: #fb4a20;
  }
`;

export const MakingOrder = styled.div`
  width: 100%;
  height: 100vh;
  background: #fb4a20;
  color: #fff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    text-align: center;
    font-size: 16px;
    margin-top: 15px;
  }

  img {
    width: 92px;
  }
`;

export const Info = styled.div`
  width: 100%;
  max-width: 410px;
  padding: 8px;
`;

export const SwitchUserSms = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;

  p {
    margin-left: 8px;
    color: #fb4a20;
  }
`;

export const RemoveUser = styled.button`
  width: 170px;
  height: 24px;
  background: #fff;
  border: none;
  border-radius: 7px;
  color: #fb4a20;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  p {
    margin-left: 4px;
  }
`;
