import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import ReactGA from 'react-ga';

import api from '../../services/api';

import { useOrder } from '../../context/order';
import { useAuth } from '../../context/auth';
import { useCart } from '../../context/cart';

import logoTakeat from '../../assets/images/iconTake.svg';

import { FiShoppingBag, FiMapPin } from 'react-icons/fi';
import {FaReceipt} from 'react-icons/fa'

import { FaStar } from 'react-icons/fa';

// import MenuNavigation from '../../components/MenuNavigation';

import {
  RestaurantClosed,
  Container,
  ProfileContent,
  Content,
  DevelopedBy,
  Message,
  Restaurants,
  Restaurant,
  ButtonsAction,
  RestaurantsAvatar,
  Avatar,
  ProfileFood,
  PromotionFood,
  LocalFood,
  PromotionBody,
  PromotionTitle,
  ProfileAvatar,
  ModalContent,
  ModalBody,
  ModalTitle,
  ModalFooter,
  StarRatings,
  ShoppingLoading,
  ButtonCoupons,
  CoupomBadge,
  ButtonOrders

} from './styles';

import coverPraca from '../../assets/images/coverPraca.jpg';

const Places = () => {
  Modal.setAppElement('#root');

  const { closeCart } = useCart();
  const {
    setRestaurantId,
    setRestaurantFantasyName,
    setShopping,
    setSmsOptions,
    setRestaurantWhatsapp,
    shoppingWasRated,
    userToken,
    setRestaurantAcceptsPicpay,
    setRestaurantAcceptsPaytime,
    setRestaurantIsOpen,
    setRestaurantProductPromotion,
    promotionLogOut,
    setRestaurantCouponCode,
    setRestaurantAcceptsWithdrawal,
    setRestaurantAcceptsDelivery,
    setRestaurantDeliveryActive,
    setRestaurantDeliveryPayments,
    setRestaurantDeliveryTime,
    setRestaurantWithdrawalTime,
    setRestaurantUsername,
    setRestaurantDeliveryMinimumPrice,
    setPicpayReferenceId,
    setIsDeliveryByDistance,
    setRestaurantAddress,
    userLogout
  } = useAuth();
  const [modalIsOpen, setIsOpen] = useState(() => {
    if (!shoppingWasRated && !!userToken) {
      return true;
    } else {
      return false;
    }
  });
  const history = useHistory();
  const { shopping_name } = useParams();
  // const [menuShow, setMenushow] = useState(false);
  const [shoppingFantasyName, setShoppingFantasyName] = useState('');
  const [coupons, setCoupons] = useState([]);
  const [shoppingId, setShoppingId] = useState('');
  const [restaurantsPriority, setRestaurantsPriority] = useState([]);
  const [restaurantsNotPriority, setRestaurantsNotPriority] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [shoppingAvatar, setShoppingAvatar] = useState();
  const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);

  useEffect(() => {
    setRestaurantCouponCode('');
    setPicpayReferenceId('');

  }, [setRestaurantCouponCode, setPicpayReferenceId]);

  const customStyles = {
    content: {
      maxHeight: '70%',
      width: '80%',
      maxWidth: '420px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  function closeModal() {
    setIsOpen(false);
  }

  // function handleScroll() {
  //   if (document.documentElement.scrollTop > 5) {
  //     setMenushow(true);
  //   } else {
  //     setMenushow(false);
  //   }
  // }

  function openAlertModal() {
    setAlertModalIsOpen(true);
  }

  function closeAlertModal() {
    setAlertModalIsOpen(false);
  }


  const verifyToken = async () => {
    try {
      await api.get('/client/verify/session')
      console.log('Token Ok')
    } catch (err){
      if (!err.response.ok) {
        switch (err.response.data.errorType) {
          case'invalid_token':
           console.log(err.message)
            userLogout();
        }
     }
    }
  }

  const handleNavigate = useCallback(
    (
      id,
      fantasy_name,
      client_pay_sms,
      is_sms_optional,
      has_sms_service,
      whatsapp,
      has_picpay_service,
      has_paytime_service,
      has_withdrawal_service,
      has_delivery_service,
      has_delivery_active,
      restaurant_delivery_payments,
      restaurant_delivery_time,
      restaurant_witdrawal_time,
      restaurant_opened,
      restaurant_product_promotion = null,
      restaurant_username,
      restaurant_delivery_minimum_price,
      is_delivery_by_distance,
      restaurant_address
    ) => {
      if (restaurant_opened.toString() === 'false') {
        alert('Restaurante fechado, favor voltar mais tarde.');
      } else {
        const restaurantWhatsapp = whatsapp
          .replace('(', '')
          .replace(')', '')
          .replace(' ', '')
          .replace(' ', '')
          .replace('+', '')
          .replace('-', '');

        closeCart();
        setRestaurantId(id);
        setRestaurantFantasyName(fantasy_name);
        setRestaurantWhatsapp(restaurantWhatsapp);
        setSmsOptions(client_pay_sms, is_sms_optional, has_sms_service);
        setRestaurantAcceptsPicpay(has_picpay_service);
        setRestaurantAcceptsPaytime(has_paytime_service);
        setRestaurantAcceptsWithdrawal(has_withdrawal_service);
        setRestaurantIsOpen(restaurant_opened);
        setRestaurantProductPromotion(restaurant_product_promotion);
        setRestaurantAcceptsDelivery(has_delivery_service);
        setRestaurantDeliveryActive(has_delivery_active);
        setRestaurantDeliveryPayments(restaurant_delivery_payments);
        setRestaurantUsername(restaurant_username);
        setRestaurantDeliveryTime(restaurant_delivery_time);
        setRestaurantWithdrawalTime(restaurant_witdrawal_time);
        setRestaurantDeliveryMinimumPrice(restaurant_delivery_minimum_price);
        setIsDeliveryByDistance(is_delivery_by_distance);
        setRestaurantAddress(restaurant_address)
        history.push(`/takeat/menu/restaurant/${id}`);
      }
    },
    [
      closeCart,
      history,
      setRestaurantFantasyName,
      setRestaurantId,
      setRestaurantWhatsapp,
      setSmsOptions,
      setRestaurantAcceptsPaytime,
      setRestaurantAcceptsPicpay,
      setRestaurantIsOpen,
      setRestaurantProductPromotion,
      setRestaurantAcceptsWithdrawal,
      setRestaurantDeliveryActive,
      setRestaurantAcceptsDelivery,
      setRestaurantUsername,
      setRestaurantDeliveryTime,
      setRestaurantWithdrawalTime,
      setRestaurantDeliveryMinimumPrice,
      setRestaurantDeliveryPayments,
      setIsDeliveryByDistance,
      setRestaurantAddress
    ],
  );

  const navigateToOrder = useCallback(() => {
    if (!!userToken) {
      history.push('/orders');
    } else {
      openAlertModal();
    }
  }, [history, userToken]);


  function navigateToCoupon(){

    history.push('/coupons')

  }

  function gaClick(){
    ReactGA.event({
      category: 'click',
      action: 'Clique no menu Cupom',
      label: 'click',
      value: 10
    })
  }

  // useEffect(() => {
  //   window.onscroll = () => handleScroll();
  // }, []);

  useEffect(() => {
    promotionLogOut();
  }, [promotionLogOut]);

  useEffect(()=>{
    verifyToken()
  },[])

  useEffect(() => {
    ReactGA.initialize('G-5R04BPMD71', {
      debug: true,
      gaOptions: {
        siteSpeedSampleRate: 100,
      },
    });
  }, []);

  useEffect(()=>{
    ReactGA.pageview(window.location.pathname + window.location.search);

  }, []);



  useEffect(() => {
    async function fetchData() {
      const response = await api.get(`/public/qrexpress/${shopping_name}`);
      const {
        id,
        username,
        fantasy_name,
        priority_restaurants,
        not_priority_restaurants,
      } = response.data;

      if (!!response.data.avatar) {
        const { url } = response.data.avatar;

        setShoppingAvatar(url);
      }

      setShopping(id, username);
      setShoppingId(id);

      setShoppingFantasyName(fantasy_name);
      setRestaurantsNotPriority(not_priority_restaurants);
      setRestaurantsPriority(priority_restaurants);

      setIsLoading(false);
    }
    fetchData();
  }, [setShopping, shopping_name]);


  //rota para saber se tem cupom novo

  useEffect(() => {
    async function getCoupons() {
      try {
        const couponsFind = await api.get(
          `/public/orders/discount-coupons/food-courts/${shoppingId}`,
        );

        if(couponsFind.data.percentageCoupons.length > 0){
          setCoupons(couponsFind.data.percentageCoupons);
        }

        if(couponsFind.data.absolutCoupons.length > 0){
          setCoupons(couponsFind.data.absolutCoupons);
        }

      } catch (err) {
        console.log(err);
      }
    }

    if(!!shoppingId){
      getCoupons();
     }
  }, [shoppingId]);



  const handleGoToShoppingRating = useCallback(() => {
    history.push('/shoppingrating');
  }, [history]);

  return isLoading ? (
    <ShoppingLoading>
      <img src={logoTakeat} alt="Logo takeat" />
      <h1>Carregando Lojas...</h1>
    </ShoppingLoading>
  ) : (
    <Container>
      <Content>
        {/* <MenuNavigation show={menuShow} /> */}

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Descrição do Item"
        >
          <ModalContent>

            <ModalTitle>
              <strong>Ei, você vem sempre aqui?</strong>
              <p>
                Que tal avaliar nosso Shopping, para que possamos manter sempre
                nosso padrão de qualidade?
              </p>
            </ModalTitle>
            <ModalBody>
              <StarRatings onClick={handleGoToShoppingRating}>
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
              </StarRatings>
            </ModalBody>
            <ModalFooter>
              <button onClick={closeModal}>Vou avaliar mais tarde</button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={alertModalIsOpen}
          onRequestClose={closeAlertModal}
          style={customStyles}
          contentLabel="Descrição do Item"
        >
          <ModalContent>
            <ModalTitle>
              <p>Você ainda não está se logou na aplicação. Para isso, conclua uma compra, preenchendo corretamente seus dados.</p>
            </ModalTitle>

            <ModalFooter>
              <button onClick={closeAlertModal}>Ok, entendi</button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Message>
          {!!shoppingAvatar && (
            <img src={shoppingAvatar} alt="logotipo do shoping" />
          )}

          <h1>Seja Bem-Vindo(a) ao {shoppingFantasyName}!</h1>
        </Message>
        <Restaurants>
          {restaurantsPriority.map(restaurant => (
            <Restaurant
              style={
                restaurant.opened.toString() === 'false'
                  ? {
                      backgroundImage: `linear-gradient(rgba(255,255,255,.8), rgba(255,255,255,.8)), url(${
                        restaurant.cover?.url || coverPraca
                      })`,
                      backgroundSize: 'cover',
                    }
                  : {
                      backgroundImage: `linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1)), url(${
                        restaurant.cover?.url || coverPraca
                      })`,
                      backgroundSize: 'cover',
                    }
              }
              key={restaurant.id}
              onClick={() => {
                handleNavigate(
                  restaurant.id,
                  restaurant.fantasy_name,
                  restaurant.client_pay_sms,
                  restaurant.is_sms_service_optional,
                  restaurant.has_sms_service,
                  restaurant.whatsapp || '',
                  restaurant.has_picpay_service,
                  restaurant.has_paytime_service,
                  restaurant.withdrawal_allowed,
                  restaurant.is_delivery_allowed || false,
                  restaurant.is_delivery_active || false,
                  restaurant.payment_methods || null,
                  restaurant.time_to_delivery || null,
                  restaurant.time_to_withdrawal || null,
                  restaurant.opened,
                  restaurant.product_promotion,
                  restaurant.username,
                  restaurant.minimun_delivery_price,
                  restaurant.is_delivery_by_distance || '',
                  restaurant.address?.state || null

                );
              }}
            >
              <ProfileFood opened={restaurant.opened}>

                <ProfileContent opened={restaurant.opened}>
                  <ProfileAvatar>
                    {!!restaurant.avatar && (
                      <img
                        src={restaurant.avatar.url}
                        alt={restaurant.fantasy_name}
                      />
                    )}
                  </ProfileAvatar>

                  <p>{restaurant.fantasy_name}</p>
                  {!!restaurant.location && (
                    <LocalFood>
                      <p>
                        {' '}
                        <FiMapPin size={10} />
                        {restaurant.location}
                      </p>
                    </LocalFood>
                  )}
                </ProfileContent>
              </ProfileFood>
              {!!restaurant.product_promotion && (
                <PromotionFood opened={restaurant.opened}>
                  <PromotionBody>
                    {!!restaurant.product_promotion.product.image && (
                      <img
                        src={restaurant.product_promotion.product.image.url}
                        alt="Lanches imagem"
                      />
                    )}

                    <PromotionTitle>
                      <p style={{ fontWeight: 'bold' }}>
                        {restaurant.product_promotion.title}
                      </p>
                      <div>
                        {restaurant.product_promotion.product
                          .price_promotion ? (
                          <p>{` ${restaurant.product_promotion.product.name} - R$ ${restaurant.product_promotion.product.price_promotion}`}</p>
                        ) : (
                          <p>{` ${restaurant.product_promotion.product.name} - R$ ${restaurant.product_promotion.product.price}`}</p>
                        )}
                      </div>
                    </PromotionTitle>
                  </PromotionBody>
                </PromotionFood>
              )}
              {restaurant.opened.toString() === 'false' && (
                <RestaurantClosed>
                  <p>Restaurante fechado</p>
                </RestaurantClosed>
              )}
            </Restaurant>
          ))}
        </Restaurants>

        <RestaurantsAvatar>
          {restaurantsNotPriority.map(restaurant => (
            <Avatar
              opened={restaurant.opened}
              key={restaurant.id}
              onClick={() => {
                handleNavigate(
                  restaurant.id,
                  restaurant.fantasy_name,
                  restaurant.client_pay_sms,
                  restaurant.is_sms_service_optional,
                  restaurant.has_sms_service,
                  restaurant.whatsapp || '',
                  restaurant.has_picpay_service,
                  restaurant.has_paytime_service,
                  restaurant.withdrawal_allowed,
                  restaurant.is_delivery_allowed || false,
                  restaurant.is_delivery_active || false,
                  restaurant.payment_methods || null,
                  restaurant.time_to_delivery || null,
                  restaurant.time_to_withdrawal || null,
                  restaurant.opened,
                  restaurant.username,
                  restaurant.minimun_delivery_price,
                  restaurant.is_delivery_by_distance || '',
                  restaurant.address?.state || null
                );
              }}
            >
              {!!restaurant.avatar && (
                <img src={restaurant.avatar.url} alt="logotipo" />
              )}

              {restaurant.opened.toString() === 'false' && (
                <RestaurantClosed priority={false}>
                  <p>Restaurante fechado</p>
                </RestaurantClosed>
              )}
            </Avatar>
          ))}
        </RestaurantsAvatar>

        <DevelopedBy>
          <p>Desenvolvido por</p>
          <a
            href="https://takeat.app/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Takeat.app
          </a>
        </DevelopedBy>

        <ButtonsAction>

          <ButtonCoupons onClick={navigateToCoupon}>
          {coupons.length > 0 && (
            <CoupomBadge>Novos</CoupomBadge>
          )}
            <FaReceipt />
            Cupons
          </ButtonCoupons>
          <p>|</p>
          <ButtonOrders onClick={()=>{navigateToOrder(); gaClick()}}>
          <FiShoppingBag />
            <span>Meus Pedidos</span>
            </ButtonOrders>
        </ButtonsAction>
      </Content>
    </Container>
  );
};

export default Places;
