import React, { useEffect, useState } from 'react';
import { uuid } from 'uuidv4';
import { Container, NoCoupons, LoadingCoupons } from './styles';
import CouponModel from '../CouponModel';

import api from '../../../services/api';

import { useAuth } from '../../../context/auth';

import semCupom from '../../../assets/images/semCupom.png';

const Percentage = () => {
  const { shoppingId } = useAuth();
  const [couponsCode, setCouponsCode] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function getCoupons() {
      try {
        setIsLoading(true);
        const coupons = await api.get(
          `/public/orders/discount-coupons/food-courts/${shoppingId}`,
        );

        setCouponsCode(coupons.data.percentageCoupons);

        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    }

    getCoupons();
  }, [shoppingId]);

  return isLoading ? (
    <LoadingCoupons>
      {' '}
      <h1>Carregando cupons ...</h1>
    </LoadingCoupons>
  ) : couponsCode.length > 0 ? (
    <Container>
      {couponsCode.map(item => (
        <CouponModel
          code={item.code}
          name={item.name}
          title={item.title}
          description={item.description}
          avatar={item.restaurant.avatar.url || null}
          restaurantName={item.restaurant.fantasy_name || null}
          key={uuid()}
        />
      ))}
    </Container>
  ) : (
    <NoCoupons>
      <img src={semCupom} alt="Ilustracao indicando sem cupons no momento" />
      <h1>Poxa, no momento não achamos cupons por aqui, volte depois.</h1>
    </NoCoupons>
  );
};

export default Percentage;
