import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useHistory } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';

import { useAuth } from '../../context/auth';

import Cash from './Cash';
import Percentage from './Percentage';

import { Container, Content, Header, Main, ButtonOrders, Nav } from './styles';

const Coupons = () => {
  const history = useHistory();
  const { shoppingName } = useAuth();

  function handleNavigate() {
    history.push(`/qrexpress/${shoppingName}`);
  }

  return (
    <Container>
      <Content>
        <Header>
          <h1>Cupons</h1>
        </Header>

        <Main>
          <Tabs>
            <Nav>
              <TabList>
                <Tab>Descontos em %</Tab>
                <Tab>Descontos em R$</Tab>
              </TabList>
            </Nav>

            <TabPanel>
              <Percentage />
            </TabPanel>

            <TabPanel>
              <Cash />
            </TabPanel>
          </Tabs>
        </Main>

        <ButtonOrders onClick={handleNavigate}>
          <FiArrowLeft />
          Voltar para Restaurantes
        </ButtonOrders>
      </Content>
    </Container>
  );
};

export default Coupons;
